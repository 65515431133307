<template>
  <div class="animated fadeIn device-prompt-box">
    <div class="tb-close" @click="$emit('close', info.tid)"></div>
    <div class="tb-name">{{ info.name }}</div>
    <div class="tb-info">
      <div class="bi-photo" @click="$vrBox(info.keyDeviceID)">
        <img
          :src="`https://oss.sense-ecology.com/publish/device-panorama/smt/${info.old_id}/${info.old_id}.JPG`"
          alt=""
        />
      </div>
      <div class="bi-info">
        <div class="ii-item">
          <div class="ii-label">部署生境</div>
          <div class="ii-high">{{ info.remark }}</div>
        </div>
        <div class="ii-item">
          <div class="ii-label">部署时间</div>
          <div class="ii-high">{{ deployTime }}</div>
        </div>
        <div class="ii-item">
          <div class="ii-label">部署经度</div>
          <div class="ii-high">{{ info.coordinates[0] }}</div>
        </div>
        <div class="ii-item">
          <div class="ii-label">部署纬度</div>
          <div class="ii-high">{{ info.coordinates[1] }}</div>
        </div>
      </div>
    </div>
    <div class="tb-intro">
      <p>{{ info.remark || "暂无介绍" }}</p>
    </div>
    <div class="nl-detail" @click="$emit('to-collect', 2)">查看详情</div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "DevicePromptBox",
  props: {
    info: {
      type: Object,
      default: {},
    },
  },
  computed: {
    deployTime() {
      // if (this.info && this.info.deployTime) return moment(this.info.deployTime).format('YYYY-MM-DD')
      return "";
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.device-prompt-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 452px;
  height: 324px;
  padding: 20px 30px;
  background: url("~_a/mapData/bubble_bg.png") no-repeat 0;

  .tb-close {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 4px;
    right: 3px;
    cursor: pointer;
    background: url("~_a/login/close.png") no-repeat 0;
  }

  .tb-name {
    font-size: 16px;
    font-weight: bold;
  }

  .tb-info {
    display: flex;
    align-items: center;

    .bi-photo {
      width: 162px;
      height: 108px;
      line-height: 108px;
      text-align: center;
      flex-shrink: 0;
      position: relative;
      cursor: pointer;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }

      &:before {
        content: "进入全景图 >";
        text-decoration: underline;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, 0.7);
        opacity: 0;
      }
      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }

    .bi-info {
      white-space: nowrap;

      .ii-item {
        display: flex;
        line-height: 32px;

        .ii-label {
          width: 80px;
          text-align: right;
          margin-right: 10px;
        }
      }

      .ii-high {
        color: #23d59c;
      }
    }
  }

  .tb-intro {
    margin-top: 8px;
    background-color: #09383f;
    padding: 12px;
  }

  .nl-detail {
    width: 144px;
    height: 36px;
    line-height: 36px;
    margin: 18px auto 0;
    text-align: center;
    cursor: pointer;
    background: url("~_a/mapData/detail_bg.png") no-repeat 0 0;
  }
}
</style>
