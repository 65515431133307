<template>
  <div class="collect-appraisal">
    <div class="ta-container">
      <div class="ac-top">
        <div class="ct-left">
          <div class="device-name">{{ info.deviceName }}</div>
          <div class="tl-audio">
            <div class="la-img">
              <div class="ai-sonogram">
                <img class="is-img" ref="sonogram" draggable="false" src="/临时/audio-img.png" alt="" />
                <div class="is-frame" ref="frame"></div>
                <div class="time-select" v-show="dragMove.editIndex !== -1">
                  <div class="es-drag es-left" ref="leftDrag">
                    <div class="sd-pull" @mousedown="handleLeftDragDown"></div>
                  </div>
                  <div class="es-drag es-right" ref="rightDrag">
                    <div class="sd-pull" @mousedown="handleRightDragDown"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="la-tip" v-show="dragMove.editIndex !== -1">拖动滑块选择开始、结束时间</div>
            <div class="la-control">
              <div class="sx-slider">
                <div class="sx-slider-rail">
                  <div class="sx-slider-process" ref="processDrag">
                    <div class="sx-slider-dot" @mousedown="handleProcessDragDown"></div>
                  </div>
                </div>
              </div>
              <div class="ac-buttons">
                <div class="cb-items">
                  <div class="bi-item" v-if="audioObj.is_play" @click="handleAudioPause()"><i class="icon-zanting"></i>
                  </div>
                  <div class="bi-item" v-else><i class="icon-bofang" @click="handleAudioPlay()"></i></div>
                </div>
                <div class="time">{{ timeS_Ms(audioObj.currentTime) }} / {{ timeS_Ms(audioObj.duration) }}</div>
              </div>
            </div>
          </div>
          <template v-if="!edit">
            <div class="tl-info">
              <div class="li-item">
                <span class="ii-label">采集时间</span>
                <span class="ii-content">2021-01-12 22:23:24</span>
              </div>
              <div class="li-item">
                <span class="ii-label">AI鉴定时间</span>
                <span class="ii-content">2021-01-12 22:23:24</span>
              </div>
            </div>
            <div class="tl-back" title="调整时间" @click="handleBackList">返回列表</div>
          </template>
          <div class="tl-time" v-show="edit && dragMove.editIndex !== -1">
            <p class="lt-tip">正在调整【人工鉴定{{ dragMove.editIndex + 1 }}】声谱图时间段</p>
            <div class="lt-btns">
              <div class="lt-btn" @click="handleSaveTime">保存</div>
              <div class="lt-btn" @click="handleAudition">试听</div>
            </div>
          </div>
        </div>
        <div class="ct-right">
          <div class="tr-appraisals" v-if="isNullAppraisal">
            <div class="ra-items">
              <template v-if="edit">
                <template v-for="(item, index) in editAppraisalList">
                  <div class="ra-item">
                    <div class="ai-head">
                      <div class="ih-title">人工鉴定{{ index + 1 }}</div>
                      <div class="ih-expand">
                        <div class="he-icon" @click="handleDeleteAppraisal">
                          <i class="icon-shanchu"></i>
                        </div>
                        <div class="he-icon" :class="item.is_open ? 'he-open' : ''"
                             @click="item.is_open = !item.is_open">
                          <i class="icon-shouqi"></i>
                        </div>
                      </div>
                    </div>
                    <div class="ai-body" v-show="item.is_open">
                      <div class="ib-edit">
                        <div class="ib-item">
                          <div class="ib-label">物种名</div>
                          <div class="ib-content">
<!--                            <input type="text" v-model="item.name" />-->
                            <animal-search-input/>
                          </div>
                        </div>
                        <div class="ib-item">
                          <div class="ib-label">鸣叫类型</div>
                          <div class="ib-content">
                            <select class="bc-select" v-model="item.type">
                              <option :value="chirp.value" :key="chirp.value" v-for="chirp in chirpOptions">{{chirp.label}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="ib-item">
                          <div class="ib-label">备注</div>
                          <div class="ib-content">
                            <input type="text" v-model="item.remark" />
                          </div>
                        </div>
                        <div class="ib-checkbox">
                          <span class="bc-check" :class="item.timeShow ? 'be-checked' : 'be-no-check'"
                                @click="handleAdjustmentTime(item, index)"></span>
                          <span>调整声谱图时间段</span>
                        </div>
                        <div class="ib-time" v-if="item.timeShow">
                          <div class="ib-item">
                            <div class="ib-label">开始时间</div>
                            <div class="ib-content">
                              <input type="text" readonly :value="timeS_Ms(item.startTime)" />
                            </div>
                          </div>
                          <div class="ib-item">
                            <div class="ib-label">结束时间</div>
                            <div class="ib-content">
                              <input type="text" readonly :value="timeS_Ms(item.endTime)" />
                            </div>
                          </div>
                          <div class="bt-edit" v-show="index !== dragMove.editIndex"
                               @click="handleEditTime(index, item)">
                            <i class="icon-edit"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="ra-add" @click="handleAddAppraisal" v-show="dragMove.editIndex === -1">增加物种</div>
              </template>
              <template v-else>
                <template v-for="(item, index) in appraisalList">
                  <div class="ra-item">
                    <div class="ai-head">
                      <div class="ih-title">人工鉴定{{ index + 1 }}</div>
                      <div class="ih-expand">
                        <div class="he-icon" :class="item.is_open ? 'he-open' : ''"
                             @click="item.is_open = !item.is_open">
                          <i class="icon-shouqi"></i>
                        </div>
                      </div>
                    </div>
                    <div class="ai-body" v-show="item.is_open">
                      <div class="ib-info">
                        <div class="ib-item">
                          <div class="ib-label">物种名</div>
                          <div class="ib-content">
                            <input class="bc-span" readonly :value="item.name" />
                          </div>
                        </div>
                        <div class="ib-item">
                          <div class="ib-label">鸣叫类型</div>
                          <div class="ib-content">
                            <input class="bc-span" readonly :value="item.typeText" />
                          </div>
                        </div>
                        <div class="ib-item">
                          <div class="ib-label">备注</div>
                          <div class="ib-content">
                            <input class="bc-span" readonly :value="item.remark" />
                          </div>
                        </div>
                        <div class="ib-item">
                          <div class="ib-label">开始时间</div>
                          <div class="ib-content">
                            <input class="bc-span" readonly :value="timeS_Ms(item.startTime)" />
                          </div>
                        </div>
                        <div class="ib-item">
                          <div class="ib-label">结束时间</div>
                          <div class="ib-content">
                            <input class="bc-span" readonly :value="timeS_Ms(item.endTime)" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
            <div class="ra-tools">
              <template v-if="edit">
                <div class="at-btn" @click="handleCancelAppraisal">取消</div>
                <div class="at-btn" @click="handleCompleteAppraisal">鉴定完成</div>
              </template>
              <div class="appraisal-btn" @click="handleOpenAppraisal" v-else>人工鉴定</div>
            </div>
          </div>
          <div class="tr-null" v-else>
            <div class="rn-tip">暂无人工鉴定结果</div>
            <div class="appraisal-btn" @click="handleOpenAppraisal">人工鉴定</div>
          </div>

        </div>
      </div>
      <div class="ac-bottom">
        <div class="cb-chart" ref="aiChart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-slider-component/theme/default.css'
import SxSelect from '_c/form/SX-Select'
import { timeS_Ms } from '@/expand/util'
import AnimalSearchInput from '_c/mapData/appraisal/AnimalSearchInput'

export default {
  name: 'CollectAppraisal',
  components: { AnimalSearchInput, SxSelect },
  props: {
    info: {
      type: Object,
      default: { deviceName: '声纹设备1' },
    },
  },
  computed: {
    /**
     * 是否空的鉴定
     **/
    isNullAppraisal() {
      return this.edit ? this.editAppraisalList.length : this.appraisalList.length
    },
  },
  data() {
    return {
      processValue: 0,
      appraisalList: [],
      editAppraisalList: [],
      edit: false,  // 是否编辑
      dragMove: {
        editIndex: -1,// 编辑的下标
        name: null,
        x: 0,
        org: 0,
        width: '',
      },  // 拖拽移动
      audioObj: {
        duration: 9.54,
        currentTime: 0,
        endTime: null,
        is_play: false,
        handlePause: false
      },
      chirpOptions: [
        { label: '觅食', value: 1 },
        { label: '求偶', value: 2 },
      ],
    }
  },
  methods: {
    timeS_Ms,
    handleProgressChange() {

    },
    handleAdjustmentTime(item, index) {
      item.timeShow = !item.timeShow
      if (item.timeShow) {
        // window.globalAudio = {duration: 8}
        this.dragMove.editIndex = index
      } else {
        this.dragMove.editIndex = -1
      }
    },
    /**
     * 画AI识别统计图
     * @param items
     */
    handleDrawChart(items) {
      const options = {
        title: [],
        series: [],
      }
      items.forEach((item, i) => {
        const x = `${100 / items.length * (i + 0.5)}%`
        options.title.push({
          text: item.name,
          padding: 0,
          textStyle: {
            fontSize: 16,
            fontWeight: '400',
            color: '#ffffff',
          },
          textAlign: 'center',
          left: x,
          bottom: 15,
        })
        options.series.push({
          name: item.name,
          type: 'pie',
          radius: [43, 53],
          center: [x, '45%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
          },
          labelLine: {
            show: false,
          },
          emphasis: {
            scale: false,
          },
          data: [
            {
              value: item.value, itemStyle: { color: '#23D59C' }, label: {
                show: true,
                formatter: '识别率\n{d}%',
                fontSize: 16,
                lineHeight: 26,
                color: '#ffffff',
              },
            },
            { value: 100 - item.value, itemStyle: { color: '#396066' } },
          ],
        })
      })
      const chart = this.$echarts.init(this.$refs.aiChart, null, { renderer: 'svg' })
      chart.setOption(options)
    },

    /**
     * 返回列表
     */
    handleBackList() {
      this.$emit('back')
    },

    /**
     * 打开人工鉴定
     */
    handleOpenAppraisal() {
      if (this.appraisalList.length) {
        this.editAppraisalList = this.appraisalList.map(item => {
          return { ...item, timeShow: item.startTime || item.endTime }
        })
      } else {
        this.handleAddAppraisal()
      }
      this.edit = true
    },

    /**
     * 人工鉴定删除
     **/
    handleDeleteAppraisal(index) {
      this.editAppraisalList.splice(index, 1)
    },

    /**
     * 增加物种鉴定
     */
    handleAddAppraisal() {
      if (this.editAppraisalList.length && !this.editAppraisalList[ this.editAppraisalList.length - 1 ].name) {
        this.$alert(`请先填写人工鉴定${this.editAppraisalList.length}的结果`)
        return false
      }
      this.editAppraisalList.push({
        name: '',
        type: '',
        remark: '',
        startTime: 0,
        endTime: window.globalAudio.duration,
        timeShow: false,
        is_open: true,
      })
    },

    /**
     * 取消鉴定
     */
    handleCancelAppraisal() {
      this.edit = false
      this.editAppraisalList = []
    },

    /**
     * 完成鉴定
     */
    handleCompleteAppraisal() {
      this.edit = false
      this.appraisalList = this.editAppraisalList.map(item => {
        const newItem = { ...item, is_open: true }
        delete newItem.timeShow
        return newItem
      })
    },

    /**
     * 保存时间段
     */
    handleSaveTime() {
      this.dragMove.editIndex = -1
      this.$refs.leftDrag.style.width = `8px`
      this.$refs.rightDrag.style.left = `${this.$refs.sonogram.width}px`
    },

    /**
     * 时间段试听
     */
    handleAudition() {
      this.handleAudioPlay(this.editAppraisalList[ this.dragMove.editIndex ].startTime, this.editAppraisalList[ this.dragMove.editIndex ].endTime)
    },

    /**
     * 编辑时间段
     * @param index
     * @param item
     */
    handleEditTime(index, item) {
      this.dragMove.editIndex = index
      const duration = Math.ceil(window.globalAudio.duration * 100) / 100
      this.$refs.leftDrag.style.width = `${item.startTime / duration * this.$refs.sonogram.width}px`
      this.$refs.rightDrag.style.left = `${item.endTime / duration * this.$refs.sonogram.width}px`
    },


    bodyMouseMoveEvent(event) {
      if (!this.dragMove.name) return false
      let x = (event.pageX - this.dragMove.x) * (2 - this.$store.state.system.displayArea.proportion) + this.dragMove.org
      let time = 0
      const duration = Math.ceil(window.globalAudio.duration * 100) / 100
      if (this.dragMove.name === 'left') {
        if (x < 8) {
          x = 8
        } else if (x > this.$refs.rightDrag.offsetLeft + 8) {
          x = this.$refs.rightDrag.offsetLeft + 8
          time = this.editAppraisalList[ this.dragMove.editIndex ].endTime
        } else {
          time = Math.ceil((x - 8) / this.$refs.sonogram.width * duration * 100) / 100
        }
        this.editAppraisalList[ this.dragMove.editIndex ].startTime = time
        this.$refs.leftDrag.style.width = `${x}px`
      } else if (this.dragMove.name === 'right') {
        if (x < this.$refs.leftDrag.offsetWidth - 8) {
          x = this.$refs.leftDrag.offsetWidth - 8
          time = this.editAppraisalList[ this.dragMove.editIndex ].startTime
        } else if (x > this.$refs.sonogram.width) {
          x = this.$refs.sonogram.width
          time = duration
        } else {
          time = Math.ceil(x / this.$refs.sonogram.width * duration * 100) / 100
        }
        this.$refs.rightDrag.style.left = `${x}px`
        this.editAppraisalList[ this.dragMove.editIndex ].endTime = time
      } else if (this.dragMove.name === 'process') {
        if (x < 0) x = 0
        if (x > 579) x = 579
        this.$refs.processDrag.style.width = `${x}px`
      }
    },

    bodyMouseUpEvent() {
      if (!this.dragMove.name) return false
      if (this.dragMove.name === 'process') {
        this.$refs.processDrag.style.transitionDuration = '0.28s'
      }
      this.dragMove.name = ''
      this.dragMove.x = 0
      this.dragMove.org = 0
    },


    /**
     * 左侧拖动
     * @param event
     * @returns {boolean}
     */
    handleLeftDragDown(event) {
      if (!this.$refs.sonogram.width) return false
      this.dragMove.name = 'left'
      this.dragMove.x = event.pageX
      this.dragMove.org = this.$refs.leftDrag.offsetWidth
    },


    /**
     * 右侧拖动
     * @param event
     * @returns {boolean}
     */
    handleRightDragDown(event) {
      if (!this.$refs.sonogram.width) return false
      this.dragMove.name = 'right'
      this.dragMove.x = event.pageX
      this.dragMove.org = this.$refs.rightDrag.offsetLeft
    },

    /**
     * 进度条拖动
     * @param event
     * @returns {boolean}
     */
    handleProcessDragDown(event) {
      if (!this.$refs.sonogram.width) return false
      this.dragMove.name = 'process'
      this.dragMove.x = event.pageX
      this.dragMove.org = this.$refs.processDrag.offsetWidth
      this.$refs.processDrag.style.transitionDuration = '0s'
    },

    /**
     * 音频播放
     * @param start
     * @param end
     */
    handleAudioPlay(start, end) {
      if (start === undefined) {
        this.$refs.frame.style.display = 'block'
        window.globalAudio.play()
      } else if (start) {
        this.handleSetProcessX(start, () => {
          if (end) this.audioObj.endTime = end
          this.$refs.frame.style.display = 'block'
          window.globalAudio.currentTime = start
          window.globalAudio.play()
        })
      } else {
        if (end) this.audioObj.endTime = end
        this.$refs.frame.style.display = 'block'
        window.globalAudio.currentTime = start
        window.globalAudio.play()
      }
    },

    /**
     * 暂停播放
     **/
    handleAudioPause() {
      this.audioObj.handlePause = true
      window.globalAudio.pause()
    },

    /**
     * 直接跳转进度位置
     * @param start
     * @param cb
     */
    handleSetProcessX(start, cb) {
      const x = start / (Math.ceil(window.globalAudio.duration * 100) / 100) * this.$refs.sonogram.width - 8 + 'px'
      this.$refs.frame.style.transitionDuration = '0s'
      this.$refs.processDrag.style.transitionDuration = '0s'
      this.$refs.frame.style.left = x
      this.$refs.processDrag.style.width = x
      setTimeout(() => {
        this.$refs.frame.style.transitionDuration = '0.28s'
        this.$refs.processDrag.style.transitionDuration = '0.28s'
        cb && cb()
      }, 20)
    },

    handleAudioTimeUpdate() {
      this.audioObj.currentTime = window.globalAudio.currentTime
      const x = window.globalAudio.currentTime / (Math.ceil(window.globalAudio.duration * 100) / 100) * this.$refs.sonogram.width + 'px'
      this.$refs.processDrag.style.width = x
      this.$refs.frame.style.left = x
      if (this.audioObj.endTime !== null && window.globalAudio.currentTime >= this.audioObj.endTime) {
        window.globalAudio.pause()
        this.audioObj.endTime = null
      }
    },

    handleAudioCanplay() {
      console.log('总时长', window.globalAudio.duration)
      window.globalAudio.removeEventListener('canplay', this.handleAudioCanplay, true)
    },

    handleAudioPlayEvent() {
      console.log('播放开始', window.globalAudio.duration)
      this.audioObj.is_play = true
    },

    handleAudioPauseEvent() {
      console.log('播放暂停', window.globalAudio.duration)
      this.audioObj.is_play = false
      if (!this.audioObj.handlePause) {
        setTimeout(() => {
          this.$refs.frame.style.display = 'none'
          this.handleSetProcessX(0)
          window.globalAudio.currentTime = 0
        }, 280)
      } else {
        this.audioObj.handlePause = false
      }
    },

  },
  mounted() {
    this.handleDrawChart([
      { name: '紫啸鸫', value: 90 },
      { name: '白鹭', value: 85 },
      { name: '灰胸竹鸡', value: 80 },
      { name: '环颈雉', value: 75 },
      { name: '黑翅长脚鹬', value: 70 },
    ])
    document.addEventListener('mousemove', this.bodyMouseMoveEvent, true)
    document.addEventListener('mouseup', this.bodyMouseUpEvent, true)
    const globalAudio = document.createElement('audio')
    globalAudio.src = '/临时/bird.mp3'
    globalAudio.load()
    globalAudio.addEventListener('canplay', this.handleAudioCanplay, true)
    globalAudio.addEventListener('timeupdate', this.handleAudioTimeUpdate, true)
    globalAudio.addEventListener('play', this.handleAudioPlayEvent, true)
    globalAudio.addEventListener('pause', this.handleAudioPauseEvent, true)
    window.globalAudio = globalAudio
  },
  unmounted() {
    document.removeEventListener('mousemove', this.bodyMouseMoveEvent, true)
    document.removeEventListener('mouseup', this.bodyMouseUpEvent, true)
    window.globalAudio.removeEventListener('canplay', this.handleAudioCanplay, true)
    window.globalAudio.removeEventListener('timeupdate', this.handleAudioTimeUpdate, true)
    globalAudio.addEventListener('play', this.handleAudioPlayEvent, true)
    globalAudio.addEventListener('pause', this.handleAudioPauseEvent, true)
    window.globalAudio = undefined
  },
}
</script>

<style lang="scss" scoped>
.collect-appraisal {
  padding-top: 15px;

  .ta-container {
    width: 948px;
    height: 679px;
    background: url('~_a/mapData/appraisal_bg.png') no-repeat 0;

    .ac-top {
      display: flex;
      width: 100%;
      height: 500px;
      border-bottom: 1px solid #23D59C;

      .ct-left {
        width: 620px;
        padding: 16px 20px;
        border-right: 1px solid #23D59C;

        .device-name {
          font-size: 16px;
          margin-bottom: 20px;
        }

        .tl-audio {

          .la-img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 578px;
            height: 181px;
            background: url('~_a/mapData/audio-big-bg.png') no-repeat 0;
            background-size: 100%;

            .ai-sonogram {
              position: relative;

              .is-img {
                width: auto;
                height: auto;
                max-width: 578px;
                max-height: 181px;
              }

              .is-frame {
                width: 1px;
                display: none;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                background-color: #FFFFFF;
                transition: left .28s linear;
              }

              .time-select {
                .es-drag {
                  position: absolute;
                  top: 0;
                  z-index: 1;

                  &:before {
                    content: '';
                    position: absolute;
                    left: 8px;
                    top: 0;
                    bottom: 0;
                    right: 8px;
                    background-color: rgba(#000, .4);
                  }

                  .sd-pull {
                    position: relative;
                    width: 8px;
                    height: 181px;
                    background-color: #FFFFFF;
                    cursor: e-resize;

                    &:before {
                      content: '';
                      position: absolute;
                      left: 3px;
                      top: 30px;
                      width: 2px;
                      height: 120px;
                      background-color: #8D8D8D;
                    }
                  }
                }

                .es-left {
                  left: -8px;

                  .sd-pull {
                    border-radius: 3px 0 0 3px;
                    float: right;
                  }
                }

                .es-right {
                  right: -8px;

                  .sd-pull {
                    border-radius: 0 3px 3px 0;
                  }
                }
              }
            }
          }

          .la-tip {
            color: #F4DB04;
            margin-top: 12px;
          }

          .sx-slider {
            margin-top: 20px;

            .sx-slider-rail {
              width: 100%;
              height: 6px;
              background-color: rgb(16, 68, 78);
              border-radius: 3px;

              .sx-slider-process {
                position: relative;
                background-color: #24e38b;
                width: 0;
                height: 100%;
                border-radius: 3px;
                transition: width .28s linear;

                .sx-slider-dot {
                  width: 30px;
                  height: 12px;
                  position: absolute;
                  right: 0;
                  top: 50%;
                  border-radius: 15px;
                  background-color: #ffffff;
                  transform: translate(50%, -50%);
                  box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, .32);
                  cursor: pointer;
                }
              }
            }
          }

          .la-control {
            margin-top: 12px;

            .ac-buttons {
              margin-top: 7px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .cb-items {
                display: flex;
                margin-top: 10px;

                .bi-item {
                  width: 42px;
                  height: 30px;
                  line-height: 30px;
                  text-align: center;
                  cursor: pointer;
                  border-radius: 2px;
                  transition: all .2s ease;
                  border: 1px solid #24E38B;

                  i {
                    font-size: 20px;
                  }

                  &:hover {
                    background: linear-gradient(90deg, rgba(12, 77, 83, 0.26), rgba(7, 104, 129, 0.26), rgba(12, 77, 83, 0.26));
                  }
                }
              }
            }
          }
        }

        .tl-info {
          display: flex;
          justify-content: space-between;
          margin: 40px 0;

          .ii-label {
            margin-right: 16px;
          }

          .ii-content {
            padding: 6px 10px;
            background-color: #052B30;
          }
        }

        .tl-back {
          width: 120px;
          height: 42px;
          line-height: 42px;
          margin: 0 auto;
          text-align: center;
          color: #23D59C;
          border: 1px solid #24D59D;
          cursor: pointer;
          transition: background-color .2s ease;

          &:hover {
            background-color: rgba(#24D59D, .1);
          }
        }

        .tl-time {
          margin-top: 40px;

          .lt-tip {
            text-align: center;
            color: #23D59C;
            margin-bottom: 20px;
          }

          .lt-btns {
            display: flex;
            justify-content: center;

            .lt-btn {
              color: #23D59C;
              width: 160px;
              height: 36px;
              line-height: 36px;
              text-align: center;
              border: 1px solid #168E66;
              cursor: pointer;
              margin: 0 10px;
            }
          }
        }
      }

      .ct-right {
        position: relative;
        width: 326px;
        height: 499px;
        padding-top: 10px;

        .tr-appraisals {
          .ra-items {
            width: 100%;
            height: 424px;
            padding: 0 12px 12px 12px;
            overflow-y: auto;

            .ra-item {
              width: 100%;
              margin-bottom: 10px;
              border: 1px solid #168E66;

              .ai-head {
                height: 34px;
                padding: 0 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .ih-expand {
                  display: flex;

                  .he-icon {
                    margin-left: 10px;
                    cursor: pointer;
                    width: 34px;
                    height: 34px;
                    line-height: 34px;
                    text-align: center;
                    transition: all .2s ease;

                    &:hover {
                      color: #e5e5e5;
                    }
                  }

                  .he-open {
                    transform: rotate(180deg);
                  }
                }
              }

              .ai-body {
                border-top: 1px solid #168E66;

                .ib-checkbox {
                  color: #23D59C;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 10px 0;

                  .bc-check {
                    display: block;
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                    margin-right: 10px;
                    transition: all .2s ease;
                  }

                  .be-no-check {
                    background: url("~_a/mapData/n_selected.png") no-repeat 0 0;
                    background-size: 100%;
                  }

                  .be-checked {
                    background: url("~_a/mapData/selected.png") no-repeat 0 0;
                    background-size: 100%;
                  }
                }

                .ib-time {
                  position: relative;
                  border-top: 1px solid #168E66;

                  .ib-content {
                    input {
                      width: 80% !important;
                    }
                  }

                  .bt-edit {
                    width: 24px;
                    height: 24px;
                    line-height: 22px;
                    text-align: center;
                    position: absolute;
                    right: 12px;
                    top: 50%;
                    transform: translateY(-50%);
                    border: 1px solid #168E66;
                    background-color: #052B30;
                    color: #23D59C;
                    cursor: pointer;
                  }
                }

                .ib-item {
                  display: flex;
                  align-items: center;
                  margin-bottom: 10px;

                  &:first-child {
                    margin-top: 10px;
                  }

                  .ib-label {
                    width: 90px;
                    padding-right: 10px;
                    text-align: right;
                    flex-shrink: 0;
                  }

                  .ib-content {
                    width: 100%;
                    padding-right: 10px;

                    ::v-deep input {
                      color: #FFFFFF;
                      padding: 0 8px;
                      width: 100%;
                      height: 36px;
                      line-height: 36px;
                      background-color: #052B30;
                      font-size: 16px;
                      outline: none;
                      border-color: transparent;
                    }

                    .bc-select {
                      width: 100%;
                      height: 36px;
                      line-height: 36px;
                      color: #FFFFFF;
                      background-color: #052B30;
                      padding: 0 8px;
                      outline: none;
                      border-color: transparent;
                      cursor: pointer;
                      font-size: 16px;
                      option {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }

            .ra-add {
              color: #23D59C;
              width: 210px;
              height: 36px;
              line-height: 36px;
              text-align: center;
              margin: 10px auto;
              border: 1px solid #168E66;
              cursor: pointer;
            }
          }

          .ra-tools {
            height: 63px;
            border-top: 1px solid #24D59D;
            display: flex;
            justify-content: center;
            align-items: center;

            .at-btn {
              width: 88px;
              height: 42px;
              line-height: 40px;
              text-align: center;
              color: #F4DB04;
              background-color: #073740;
              border: 1px solid #24D59D;
              margin-right: 10px;
              cursor: pointer;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        .appraisal-btn {
          width: 210px;
          height: 36px;
          line-height: 34px;
          border: 1px solid #168E66;
          cursor: pointer;
          color: #23D59C;
          text-align: center;
          background-color: #052B30;
        }

        .tr-null {
          width: 302px;
          height: 480px;
          margin-left: 12px;
          color: #23D59C;
          text-align: center;
          white-space: nowrap;
          background: url('~_a/mapData/ca-null-bg.png') no-repeat 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .appraisal-btn {
            margin-top: 22px;
          }
        }
      }
    }

    .ac-bottom {
      .cb-chart {
        width: 948px;
        height: 176px;
      }
    }
  }
}
</style>
