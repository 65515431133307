<template>
  <div class="collect-charts">
    <div class="tc-tabs">
      <div
        class="ct-tab"
        :class="selTab === 0 ? 'ct-sel' : ''"
        @click="handleSelTabClick(0)"
      >
        监测年报
      </div>
      <div
        class="ct-tab"
        :class="selTab === 2 ? 'ct-sel' : ''"
        @click="handleSelTabClick(2)"
      >
        监测季报
      </div>
      <div
        class="ct-tab"
        :class="selTab === 1 ? 'ct-sel' : ''"
        @click="handleSelTabClick(1)"
      >
        监测月报
      </div>
      <div
        class="ct-tab"
        :class="selTab === 3 ? 'ct-sel' : ''"
        @click="handleSelTabClick(3)"
      >
        活动节律
      </div>
    </div>
    <div
      :class="[
        'tc-panel',
        data_status === 0 ? 'cp_loading' : data_status === 2 ? 'cp_error' : '',
      ]"
    >
      <div class="pl_loading" v-if="data_status === 0">
        <span class="tip">正在加载...</span>
      </div>
      <!-- <div class="pl_error">暂无数据</div> -->
      <div class="pl_error" v-else-if="data_status === 2">暂无数据</div>
      <div class="cp-head">
        <div class="ph_select">
          <sx-select
            :options="years"
            :value="year"
            @update:value="handleYearChange"
          />
          <sx-select
            :options="seasons"
            :value="season"
            @update:value="handleSeasonChange"
            v-if="selTab === 2"
          />
          <sx-select
            :options="months"
            :value="month"
            @update:value="handleMonthChange"
            v-if="selTab === 1"
          />
        </div>
        <p class="ph-zhu">以下数据主要针对当前设备</p>
      </div>
      <div class="cp-content">
        <div class="animated fadeIn pc-box2" v-show="selTab === 3">
          <div class="cb-chart6" id="collect-chart6"></div>
        </div>
        <div class="animated fadeIn pc-box1" v-show="selTab !== 3">
          <div class="cb-col1">
            <div class="cb-chart1" id="collect-chart1"></div>
            <div class="cb-chart2" id="collect-chart2"></div>
          </div>
          <div class="cb-col2">
            <div class="cb-chart3" id="collect-chart3"></div>
            <div class="cb-chart4" id="collect-chart4"></div>
          </div>
          <div class="cb-col3">
            <div class="cb-chart5" id="collect-chart5"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SxSelect from "_c/form/SX-Select";
import {
  GetDeviceRecogRhythm,
  GetRecogByDevice,
  getDeviceAnimal,
} from "_r/apis";

export default {
  name: "CollectCharts",
  components: { SxSelect },
  data() {
    return {
      selTab: 0,
      year: new Date().getFullYear(),
      season: 0,
      month: 1,
      years: [
        // { label: '全部年份', value: 0 }
      ],
      seasons: [
        { label: "春季", value: 0 },
        { label: "夏季", value: 1 },
        { label: "秋季", value: 2 },
        { label: "冬季", value: 3 },
      ],
      months: [
        // { label: '全部月份', value: 0 },
        { label: "一月", value: 1 },
        { label: "二月", value: 2 },
        { label: "三月", value: 3 },
        { label: "四月", value: 4 },
        { label: "五月", value: 5 },
        { label: "六月", value: 6 },
        { label: "七月", value: 7 },
        { label: "八月", value: 8 },
        { label: "九月", value: 9 },
        { label: "十月", value: 10 },
        { label: "十一月", value: 11 },
        { label: "十二月", value: 12 },
      ],
      deviceID: null,
      loading: true,
      data_status: 0, // 0：加载中, 1:加载成功, 2:加载失败
    };
  },
  methods: {
    handleYearChange(year) {
      this.year = year;
      this.handleSelTabClick();
    },
    handleSeasonChange(val) {
      this.season = val;
      this.handleSelTabClick();
    },
    handleMonthChange(val) {
      this.month = val;
      this.handleSelTabClick();
    },

    async handleSelTabClick(type) {
      if (type !== undefined) this.selTab = type;
      let old_type = this.selTab;
      this.data_status = 0;
      this.$echarts
        .init(document.getElementById("collect-chart6"), null, {
          renderer: "svg",
        })
        .clear();
      this.$echarts
        .init(document.getElementById("collect-chart1"), null, {
          renderer: "svg",
        })
        .clear();
      this.$echarts
        .init(document.getElementById("collect-chart2"), null, {
          renderer: "svg",
        })
        .clear();
      this.$echarts
        .init(document.getElementById("collect-chart3"), null, {
          renderer: "svg",
        })
        .clear();
      this.$echarts
        .init(document.getElementById("collect-chart4"), null, {
          renderer: "svg",
        })
        .clear();
      this.$echarts
        .init(document.getElementById("collect-chart5"), null, {
          renderer: "svg",
        })
        .clear();
      if (this.selTab === 3) {
        const res = await GetDeviceRecogRhythm({
          deviceID: this.deviceID,
          year: this.year,
        });
        if (this.selTab !== old_type) return;
        if (res.messageType !== 0) {
          this.$alert(res.message || "无数据");
          this.data_status = 2;
          return;
        }
        this.data_status = 1;
        // 图六
        this.handleDrawChart61([
          {
            name: "春",
            color: "36,227,139",
            data: (res.springData || []).map((v) => v.number),
          },
          {
            name: "夏",
            color: "0,163,248",
            data: (res.summerData || []).map((v) => v.number),
          },
          {
            name: "秋",
            color: "226,122,28",
            data: (res.autumnData || []).map((v) => v.number),
          },
          {
            name: "冬",
            color: "14,210,189",
            data: (res.winterData || []).map((v) => v.number),
          },
          // res.springData || [],
          // res.summerData || [],
          // res.autumnData || [],
          // res.winterData || []
        ]);
      } else {
        const res = await GetRecogByDevice({
          deviceID: this.deviceID,
          dateSelectType: this.selTab,
          year: this.year,
          month: this.month,
          season: this.season,
        });
        if (this.selTab !== old_type) return;
        if (res.messageType !== 0 || !res.data) {
          this.$alert(res.message || "无数据");
          this.data_status = 2;
          return;
        }
        this.data_status = 1;
        this.handleChart1Draw(
          "collect-chart1",
          "相对多度情况",
          res.data.map((item) => ({
            name: item.recogAnimalName,
            value: item.number,
          })),
          "相对多度指数"
        );
        // const res2 = await getDeviceAnimal({ deviceID: this.deviceID })
        // 含动物信息列表、目数量、科数量、红色名录数量
        const newAnimals = [],
          orderCount = [],
          familyCount = [],
          redName = [0, 0, 0, 0],
          residences = [
            { name: "留鸟", value: 0, i: "R" },
            { name: "冬候鸟", value: 0, i: "W" },
            { name: "夏候鸟", value: 0, i: "S" },
            { name: "旅鸟", value: 0, i: "P" },
            { name: "迷鸟", value: 0, i: "V" },
          ];
        // I级、II级
        let iCount = 0,
          iiCount = 0;
        res.data.forEach(async (item) => {
          let animal = this.$store.getters.getAnimalById(item.recogAnimalID);
          if (animal) {
            if (animal.orderCN && orderCount.indexOf(animal.orderCN) === -1)
              orderCount.push(animal.orderCN);
            if (animal.familyCN && familyCount.indexOf(animal.familyCN) === -1)
              familyCount.push(animal.familyCN);
          } else {
            animal = await this.$store.dispatch(
              "updateAnimalByID",
              item.recogAnimalID
            );
          }
          if (animal.chinaLevel === "一级") iCount++;
          if (animal.chinaLevel === "二级") iiCount++;
          if (animal.chinaLevel === "极危（CR）") redName[0]++;
          if (animal.chinaLevel === "濒危（EN）") redName[1]++;
          if (animal.chinaLevel === "易危（VU）") redName[2]++;
          if (animal.chinaRedLevel === "近危（NT）") redName[3]++;
          if (animal.resident === "R") residences[0].value++;
          if (animal.resident === "W") residences[0].value++;
          if (animal.resident === "S") residences[0].value++;
          if (animal.resident === "P") residences[0].value++;
          if (animal.resident === "V") residences[0].value++;
          newAnimals.push(animal);
        });
        // 保护地数据 目总数量、科总数量
        const oc = [],
          fc = [];
        this.$store.state.speciesBank.animals.forEach((item) => {
          if (item.orderCN && oc.indexOf(item.orderCN) === -1)
            oc.push(item.orderCN);
          if (item.familyCN && fc.indexOf(item.familyCN) === -1)
            fc.push(item.familyCN);
        });
        // 图二 鸟类208种，47科，16目，
        this.handleChart2Draw([
          {
            count: orderCount.length,
            value: Math.floor((orderCount.length / 16) * 10000) / 100,
          },
          {
            count: familyCount.length,
            value: Math.floor((familyCount.length / 47) * 10000) / 100,
          },
          {
            count: res.data.length,
            value: Math.floor((res.data.length / 208) * 10000) / 100,
          },
          // { count: orderCount.length, value: Math.floor(orderCount.length / (oc.length || 1) * 10000) / 100 },
          // { count: familyCount.length, value: Math.floor(familyCount.length / (fc.length || 1) * 10000) / 100 },
          // { count: res.animals.length, value: Math.floor(res2.animals.length / (this.$store.state.speciesBank.animals.length || 1) * 10000) / 100 },
        ]);
        console.log(this.$store.state.speciesBank.animals);
        // 图三
        this.handleChart1Draw(
          "collect-chart3",
          "国家保护等级",
          [
            { name: "I级", value: iCount },
            { name: "II级", value: iiCount },
          ],
          "数量（种）"
        );
        // 图四
        this.handleChart1Draw(
          "collect-chart4",
          "IUCN红色名录",
          [
            { name: "极危", value: redName[0] },
            { name: "濒危", value: redName[1] },
            { name: "易危", value: redName[2] },
            { name: "近危", value: redName[3] },
          ],
          "数量（种）"
        );
        // 图五
        this.handleChart3Draw(residences);
      }
    },
    handleDrawChart61(items) {
      const options = {
        grid: {
          top: 55,
          left: 60,
          right: 30,
          bottom: 30,
        },
        title: {
          text: "四季动物节律情况",
          left: "center",
          textStyle: {
            color: "#ffffff",
            fontSize: 16,
          },
        },
        legend: {
          itemWidth: 20,
          itemHeight: 4,
          itemGap: 20,
          icon: "rect",
          textStyle: {
            color: "#ffffff",
          },
          top: 25,
          right: 30,
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#22d384",
                width: 2,
              },
            },
            axisLabel: {
              color: "#ffffff",
              interval: 3,
            },
            splitLine: {
              show: false,
            },
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            data: [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20, 21, 22, 23,
            ].map((h) => `${h < 10 ? `0${h}` : h}时`),
          },
        ],
        yAxis: [
          {
            name: "条数",
            nameTextStyle: {
              color: "#ffffff",
              align: "center",
            },
            type: "value",
            min: 0,
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#22d384",
                width: 2,
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#ffffff",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [],
      };
      items.forEach((item) => {
        options.series.push({
          name: item.name,
          type: "line",
          symbol: "none",
          label: {
            show: false,
          },
          itemStyle: {
            color: `rgb(${item.color})`,
          },
          areaStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: `rgba(${item.color}, 0.8)`,
                  },
                  {
                    offset: 1,
                    color: `rgba(${item.color}, 0)`,
                  },
                ],
                false
              ),
            },
          },
          data: item.data,
        });
      });
      // const chart = this.$echarts.init(this.$refs.yrChart1, null, { renderer: 'svg' })
      // chart.setOption(options)
      const chart = this.$echarts.init(
        document.getElementById("collect-chart6"),
        null,
        { renderer: "svg" }
      );
      chart.setOption(options, true);
    },

    /**
     * 图一
     */
    handleChart1Draw(target, title, items, yName) {
      const chart = this.$echarts.init(document.getElementById(target), null, {
        renderer: "svg",
      });
      const options = {
        title: {
          text: title,
          textStyle: {
            color: "#ffffff",
            fontSize: 16,
          },
          x: "center",
          y: 5.5,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: 52,
          right: 32,
          top: 40,
          bottom: 32,
        },
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#ffffff",
            fontSize: 14,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#24E38B",
            },
          },
          data: [],
        },
        yAxis: {
          type: "value",
          name: yName,
          splitNumber: 3,
          minInterval: 1,
          nameTextStyle: {
            color: "#ffffff",
            fontSize: 14,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#24E38B",
            },
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: "#ffffff",
            fontSize: 12,
          },
        },
        series: [
          {
            data: [],
            type: "bar",
            barWidth: 20,
            itemStyle: {
              color: "#24E38B",
            },
          },
        ],
        animationDuration: 1500,
      };
      items.forEach((item, index) => {
        if (index > 5) return;
        options.xAxis.data.push(item.name);
        options.series[0].data.push(item.value);
      });
      chart.setOption(options);
    },

    /**
     * 图二
     */
    handleChart2Draw(data) {
      const items = [
        {
          name: "目",
          value: data[0].value,
          color: "#1575E4",
          left: `${100 / 6}%`,
          count: data[0].count,
        },
        {
          name: "科",
          value: data[1].value,
          color: "#E27A1C",
          left: "50%",
          count: data[1].count,
        },
        {
          name: "种",
          value: data[2].value,
          color: "#24E28A",
          left: `${(100 / 6) * 5}%`,
          count: data[2].count,
        },
      ];
      const options = { title: [], series: [] };
      items.forEach((item) => {
        options.title.push({
          text: item.name + "占保护区总比",
          x: item.left,
          bottom: 6,
          textAlign: "center",
          textStyle: {
            fontWeight: 400,
            fontSize: 16,
            color: "#ffffff",
          },
        });

        options.series.push({
          name: item.name,
          type: "pie",
          radius: [34, 44],
          center: [item.left, "45%"],
          hoverAnimation: false,
          labelLine: {
            show: false,
          },
          data: [
            {
              value: item.value,
              label: {
                formatter: `${item.count}${item.name}\n${item.value}%`,
                position: "center",
                align: "center",
                lineHeight: 24,
                show: true,
                textStyle: {
                  fontSize: 14,
                  color: "#ffffff",
                },
              },
              itemStyle: {
                color: item.color,
              },
            },
            {
              value: 100 - item.value,
              itemStyle: {
                normal: {
                  color: "#395d61",
                },
              },
            },
          ],
        });
      });
      const chart = this.$echarts.init(
        document.getElementById("collect-chart2"),
        null,
        { renderer: "svg" }
      );
      chart.setOption(options);
    },

    /**
     * 图五
     */
    handleChart3Draw(items) {
      const chart = this.$echarts.init(
        document.getElementById("collect-chart5"),
        null,
        { renderer: "svg" }
      );
      chart.setOption({
        title: {
          text: "鸟类居留型",
          x: "center",
          y: 5.5,
          textStyle: {
            color: "#ffffff",
            fontSize: 16,
          },
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "鸟类居留型",
            type: "pie",
            radius: [42, 52],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "outside",
              color: "#ffffff",
              fontSize: 14,
            },
            labelLine: {
              show: true,
              lineStyle: {
                color: "#ffffff",
              },
            },
            data: items,
          },
        ],
      });
    },

    /**
     * 图六
     */
    handleChart6Draw(items) {
      const options = {
        title: {
          text: "物种声纹采集数量",
          textStyle: {
            color: "#ffffff",
            fontWeight: "normal",
            fontSize: 16,
          },
          x: "center",
          y: 16,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          selectedMode: false,
          textStyle: {
            color: "#ffffff",
            fontSize: 16,
          },
          top: 15,
          right: 20,
          itemWidth: 8,
          itemHeight: 16,
        },
        grid: {
          left: 80,
          right: 20,
          bottom: 43,
        },
        xAxis: {
          type: "category",
          splitNumber: 8,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#23D59C",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: "#ffffff",
            fontSize: 16,
            interval: 0,
          },
          data: [],
        },
        yAxis: [
          {
            type: "value",
            name: "（条）",
            nameTextStyle: {
              color: "#ffffff",
              fontSize: 16,
              align: "right",
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#23D59C",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#23D59C",
              },
            },
            axisLabel: {
              show: true,
              color: "#ffffff",
              fontSize: 16,
            },
          },
          {
            type: "value",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#23D59C",
              },
            },
          },
        ],
        series: [
          {
            name: "采集数（条）",
            data: [],
            type: "bar",
            barWidth: 8,
            itemStyle: {
              borderRadius: 4,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#23D59C" },
                { offset: 1, color: "#41EBB9" },
              ]),
            },
          },
        ],
      };
      items.forEach((item) => {
        options.xAxis.data.push(item.name);
        options.series[0].data.push(item.value);
      });
      const chart = this.$echarts.init(
        document.getElementById("collect-chart6"),
        null,
        { renderer: "svg" }
      );
      chart.setOption(options, true);
    },

    /**
     * 加载
     */
    async handleLoad(deviceID) {
      this.deviceID = deviceID;
      this.handleSelTabClick();
    },
  },
  created() {
    let year = new Date().getFullYear();
    while (year >= 2022) {
      this.years.push({ label: `${year}年`, value: year });
      year--;
    }

    // this.handleYearChange(years[years.length - 1].value)
  },
};
</script>

<style lang="scss" scoped>
.collect-charts {
  margin-bottom: 38px;

  .tc-tabs {
    display: flex;
    padding-left: 17px;

    .ct-tab {
      width: 126px;
      height: 32px;
      line-height: 32px;
      color: #23d59c;
      border: 1px solid #23d59c;
      text-align: center;
      margin-right: 12px;
      transition: all 0.2s ease;

      &:not(.ct-sel) {
        cursor: pointer;
      }
    }

    .ct-sel {
      color: #000000;
      background: #23d59c;
    }
  }

  .tc-panel {
    width: 948px;
    height: 322px;
    position: relative;
    background: url("~_a/mapData/coolect-chart-bg.png") no-repeat 0;

    .cp-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      padding: 0 20px;
      border-bottom: 1px solid #23d59c;

      .ph_select {
        display: flex;

        .sx-select {
          margin-right: 20px;
        }
      }

      .ph-zhu {
        color: #ffe400;
      }
    }

    .cp-content {
      position: relative;

      .pc-box1 {
        display: flex;
        height: 270px;

        .cb-col1 {
          position: relative;
          z-index: 1;
          border-right: 1px solid #23d59c;

          &:before {
            content: "";
            position: absolute;
            left: 5.5px;
            top: 126px;
            width: 411px;
            height: 1px;
            background: url("~_a/mapData/collect-line1.png") no-repeat 0;
          }

          .cb-chart1 {
            width: 422px;
            height: 126px;
          }

          .cb-chart2 {
            width: 422px;
            height: 144px;
          }
        }

        .cb-col2 {
          position: relative;
          z-index: 1;

          &:before {
            content: "";
            position: absolute;
            left: 6px;
            top: 3.5px;
            width: 276px;
            height: 263px;
            background: url("~_a/mapData/collect-line2.png") no-repeat 0;
          }

          .cb-chart3 {
            width: 282px;
            height: 126px;
          }

          .cb-chart4 {
            width: 282px;
            height: 144px;
          }
        }

        .cb-col3 {
          position: relative;
          z-index: 1;

          .cb-chart5 {
            width: 242px;
            height: 270px;
          }
        }
      }

      .pc-box2 {
        .cb-chart6 {
          width: 948px;
          height: 270px;
        }
      }
    }
  }

  .cp_loading,
  .cp_error {
    .cb-col1 {
      border-right-color: rgba(#000, 0) !important;

      &::before {
        background: initial !important;
      }
    }

    .cb-col2 {
      &::before {
        background: initial !important;
      }
    }
  }

  .cp_loading {
    .pl_loading {
      position: absolute;
      left: 0;
      top: 0;
      width: 948px;
      height: 322px;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: "";
        display: block;
        background: url("~_a/mapData/coolect-chart-bg.png") no-repeat 0;
        opacity: 0.5;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }

      .tip {
        position: relative;
        z-index: 3;
      }
    }
  }

  .cp_error {
    .cb-col1 {
      border-right-color: rgba(#000, 0) !important;

      &::before {
        background: initial !important;
      }
    }

    .cb-col2 {
      &::before {
        background: initial !important;
      }
    }

    .pl_error {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
