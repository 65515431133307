<template>
  <div class="animal-info">
    <div class="li-tabs">
      <div class="it-tab" :class="selTab === 1 ? 'it-tab-sel' : ''" @click="selTab = 1">基本信息</div>
      <div class="it-tab" :class="selTab === 2 ? 'it-tab-sel' : ''" @click="selTab = 2">监测数据</div>
    </div>
    <div class="li-panel">
      <div class="ip-panel1" v-show="selTab === 1">
        <div class="al-title">基本信息</div>
        <div class="al-photo">
          <img class="lp-img" draggable="false" :src="info.imgUrl[0]" alt="" v-if="info.imgUrl && info.imgUrl.length" />
          <img class="lp-img" draggable="false" src="~_a/mapData/photo-none.png" alt="" v-else />
        </div>
        <div class="al-info">
          <div class="ali-name">{{ info.name }}</div>
          <div class="ali-item">
            <span class="ii-label">拼音</span>
            <span class="ii-py">{{ info.pinyin }}</span>
          </div>
          <div class="ali-item">
            <span class="ii-label">学名</span>
            <span class="ii-py">{{ info.scientificName }}</span>
          </div>
          <div class="ali-title">物种分类</div>
          <div class="ali-item">
            <span class="ii-label">目名</span>
            <span class="li-high">{{ info.orderCN }}</span>
          </div>
          <div class="ali-item">
            <span class="ii-label">科名</span>
            <span class="li-high">{{ info.familyCN }}</span>
          </div>
          <div class="ali-item">
            <span class="ii-label">属名</span>
            <span class="li-high">{{ info.genusCN }}</span>
          </div>
          <div class="ali-title">保护等级</div>
          <div class="ali-item">
            <span class="ii-label">IUCN等级</span>
            <span class="li-high">{{ info.iUCNLevel }}</span>
          </div>
          <div class="ali-item">
            <span class="ii-label">国家保护等级</span>
            <span class="li-high">{{ info.chinaLevel }}</span>
          </div>
          <div class="ali-item">
            <span class="ii-label">中国脊椎动物红色名录等级</span>
            <span class="li-high">{{ info.chinaRedLevel }}</span>
          </div>
          <div class="ali-item">
            <span class="ii-label">是否为中国特有种</span>
            <span class="li-high">{{ info.isChinaAlone }}</span>
          </div>
          <div class="ali-item">
            <span class="ii-label">是否为广东省重点保护</span>
            <span class="li-high">{{ info.isGDProtected }}</span>
          </div>
          <div class="ali-item">
            <span class="ii-label">CITES附录等级</span>
            <span class="li-high">{{ info.citesLevel }}</span>
          </div>
        </div>
      </div>
      <div class="ip-panel2" v-show="selTab === 2">
        <div class="al-title">监测数据</div>
        <div class="al-data">
          <div class="ali-item">
            <span class="ii-label">采集设备数</span>
            <span class="li-high">0</span>
          </div>
          <div class="ali-item">
            <span class="ii-label">采集声音数</span>
            <span class="li-high">{{voiceTotal}}</span>
          </div>
        </div>
        <div class="nl-list" @scroll="handleVoiceScroll">
          <div class="ll-item" v-for="item in voiceList" :key="item.keyRecogID">
            <div class="li-top">
              <div class="li-audio" v-audio-player="item.recogSoundUrl">
                <img :src="item.recogSpectUrl" alt="" class="ia-img" />
              </div>
              <div class="li-sbl">
                <div>识别率</div>
                <div class="nl-high">
                  {{ item.animals.length ? ((item.animals[ 0 ].score * 100).toFixed(2) + '%') : '——' }}
                </div>
              </div>
            </div>
            <div class="li-info">
              <div>物种：<span class="nl-high">{{ item.animals.length ? item.animals[ 0 ].name : '识别失败' }}</span></div>
              <div>采集时间：<span class="nl-high">{{ item.collectionTime }}</span></div>
            </div>
          </div>
        </div>
        <div class="li-high la-detail" @click="$emit('to-collect', 1)">详 情</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVoiceList } from '_r/apis'

export default {
  name: 'AnimalInfo',
  props: {
    info: {
      type: Object,
      default: {},
    },
    toCollect: Function,
  },
  data() {
    return {
      selTab: 1,
      voiceTotal: 0,
      voiceList: [],
      pageIndex: 1,
    }
  },
  watch: {
    info() {
      this.handleVoiceData(1)
    },
  },
  methods: {
    /**
     * 声纹数据
     * @returns {Promise<void>}
     */
    async handleVoiceData(pageIndex) {
      this.pageIndex = pageIndex
      if (pageIndex === 1) {
        this.voiceTotal = 0
        this.voiceList = []
      }
      const res = await getVoiceList({ animalID: this.info.keyAnimalID, pageSize: 20, pageIndex: this.pageIndex })
      res.data.forEach(item => {
        this.voiceList.push(item)
      })
      this.voiceTotal = res.totalCount
    },

    /**
     * 声纹数据滚到底
     * @param event
     */
    handleVoiceScroll(event) {
      if (this.voiceList.length >= this.voiceTotal) return
      if (event.target.scrollHeight - event.target.scrollTop === event.target.offsetHeight) {
        this.handleVoiceData(this.pageIndex + 1)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.animal-info {
  position: relative;
  width: 100%;
  height: 100%;

  .li-tabs {
    position: absolute;
    left: -28px;
    top: 50%;
    transform: translateY(-50%);

    .it-tab {
      width: 28px;
      height: 90px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 16px;
      line-height: 18px;
      background: url("~_a/mapData/tab-bg9.png") no-repeat 0 0;
      transition: all .2s ease;

      &:hover {
        color: #57efd8;
        background: url("~_a/mapData/tab-bg12.png") no-repeat 0 0;
      }

      &:first-child {
        background: url("~_a/mapData/tab-bg8.png") no-repeat 0 0;

        &:hover {
          background: url("~_a/mapData/tab-bg11.png") no-repeat 0 0;
        }
      }

      &:last-child {
        background: url("~_a/mapData/tab-bg7.png") no-repeat 0 0;

        &:hover {
          background: url("~_a/mapData/tab-bg10.png") no-repeat 0 0;
        }
      }
    }

    .it-tab-sel {
      color: #57efd8;
      background: url("~_a/mapData/tab-bg12.png") no-repeat 0 0;

      &:first-child {
        background: url("~_a/mapData/tab-bg11.png") no-repeat 0 0;
      }

      &:last-child {
        background: url("~_a/mapData/tab-bg10.png") no-repeat 0 0;
      }

    }
  }

  .li-high {
    color: #56f6b5;
  }

  .li-panel {
    width: 100%;
    height: 100%;
    padding: 10px 0 0 18px;
    position: relative;
    white-space: nowrap;

    .ip-panel1 {
      .al-photo {
        width: 282px;
        height: 188px;
        line-height: 188px;
        text-align: center;
        margin: 12px 0 20px;

        .lp-img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }

      .al-info {
        width: 283px;
        height: 569px;
        padding: 15px 24px;
        background: url('~_a/mapData/animal-base-info.png') no-repeat 0;

        .ali-name {
          font-size: 20px;
          font-weight: 400;
          margin-bottom: 10px;
        }

        .ali-item {
          line-height: 34px;

          .ii-label {
            padding-right: 12px;
          }

          .ii-py {
            font-size: 14px;
            font-style: italic;
          }
        }

        .ali-title {
          padding: 0 9px;
          width: 198px;
          height: 33px;
          line-height: 33px;
          margin: 12px 0;
          background: url('~_a/mapData/title-bg.png') no-repeat 0;
        }
      }
    }

    .ip-panel2 {
      .al-data {
        width: 285px;
        height: 116px;
        padding: 15px 24px;
        margin: 12px 0;
        background: url('~_a/mapData/animal-data-bg.png') no-repeat 0;

        .ali-item {
          line-height: 40px;

          .ii-label {
            padding-right: 12px;
          }
        }
      }

      .nl-list {
        margin-top: 30px;
        height: 580px;
        overflow-y: auto;

        .ll-item {
          width: 283px;
          height: 133px;
          padding: 15px 24px;
          background: url('~_a/mapData/recog-bg.png') no-repeat 0 0;
          margin-bottom: 12px;
          line-height: 24px;

          .li-top {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            .li-audio {
              width: 168px;
              height: 52px;
              line-height: 52px;
              text-align: center;
              background: url('~_a/mapData/audio-bg.png') no-repeat 0;

              .ia-img {
                width: 100%;
                height: 100%;
              }
            }

            .li-sbl {
              margin-left: 18px;
              text-align: center;
            }
          }
        }
      }

      .la-detail {
        width: 144px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        margin: 12px auto;
        cursor: pointer;
        background: url('~_a/mapData/detail_bg.png') no-repeat 0;
      }
    }
  }
}
</style>
