<template>
  <div class="animal-of-month">
    <div id="animal-of-month-chart"></div>
  </div>
</template>

<script>
import { getValueByMonth } from '_r/apis'

export default {
  name: 'AnimalOfMonth',
  data() {
    return {}
  },
  methods: {
    /**
     * 画图表
     */
    handleDrawChart(data) {
      const chart = this.$echarts.init(document.getElementById('animal-of-month-chart'), null, { renderer: 'svg' })
      chart.setOption({
        title: {
          text: '物种声纹每月采集数量',
          textStyle: {
            color: '#ffffff',
            fontWeight: 'normal',
            fontSize: 16,
          },
          x: 'center',
          y: 16,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          selectedMode: false,
          textStyle: {
            color: '#ffffff',
            fontSize: 16,
          },
          top: 15,
          right: 20,
          itemWidth: 8,
          itemHeight: 16,
        },
        grid: {
          left: 80,
          right: 20,
          bottom: 43
        },
        xAxis: {
          type: 'category',
          splitNumber: 8,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#23D59C',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: '#ffffff',
            fontSize: 16,
          },
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        },
        yAxis: [
          {
            type: 'value',
            name: '（条）',
            nameTextStyle: {
              color: '#ffffff',
              fontSize: 16,
              align: 'right'
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#23D59C',
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#23D59C',
              },
            },
            axisLabel: {
              show: true,
              color: '#ffffff',
              fontSize: 16,
            },
          },
          {
            type: 'value',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#23D59C',
              },
            },
          },
        ],
        series: [{
          name: '采集数（条）',
          data,
          type: 'bar',
          barWidth: 8,
          itemStyle: {
            borderRadius: 4,
            color: new this.$echarts.graphic.LinearGradient(
              0, 0, 0, 1,
              [
                { offset: 0, color: '#23D59C' },
                { offset: 1, color: '#41EBB9' },
              ],
            ),
          },
        }],
      })
    },

    /**
     * 加载图表
     */
    async handleLoad(id) {
      const res = await getValueByMonth({ animalID: id })
      if (res.messageType === 0) {
        this.handleDrawChart(res.data.map(item => item.value))
      }
      // const randomNum = () => {
      //   return Math.ceil(Math.random() * 1000)
      // }
      // const data = []
      // for (let i = 0; i < 12; i++) {
      //  data.push(randomNum())
      // }
      // this.handleDrawChart(data)
    }
  },
  mounted() {
    this.handleLoad()
  },
}
</script>

<style lang="scss" scoped>
.animal-of-month {
  margin-top: 34px;
  background: url('~_a/mapData/animal-chart-bg.png') no-repeat 0;

  #animal-of-month-chart {
    width: 948px;
    height: 355px;
  }
}
</style>
