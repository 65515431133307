<template>
  <div class="animal-search-input">
    <div class="hi-input">
      <input type="text" v-model="searchWord" ref="searchInput" @focus="handleSearchFocus" @blur="handleSearchBlur" @input="handleSearchInput" />
<!--      <span class="ii-clear" v-show="focus && searchWord" @click="handleClearSearch">-->
<!--        <i class="icon-clear"></i>-->
<!--      </span>-->
    </div>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" duration="200">
      <div class="hi-result" v-show="focus">
        <ul>
          <template v-for="(item, index) in searchList">
            <li class="ir-option" :class="item.value === selectItem.value ? 'sel-li' : (waitingSelectIndex === index ? 'hover-li' : '')" @click="handleResultSelect(item)">{{ item.label }}</li>
          </template>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
// import birds from '_v/mapData/birds'

export default {
  name: 'AnimalSearchInput',
  data() {
    return {
      // birds,
      searchWord: '',
      focus: false,
      searchList: [],
      orderList: [],  // 所有目
      familyList: [], // 所有科
      selectItem: {},
      waitingSelectIndex: -1,
    }
  },
  methods: {
    /**
     * 搜索框焦点获取
     **/
    handleSearchFocus() {
      this.focus = true
      this.handleSearchInput()
    },
    /**
     * 搜索框焦点失去
     **/
    handleSearchBlur() {
      this.focus = false
      if (this.selectItem.value)  {
        this.searchWord = this.selectItem.label
      } else {
        this.searchWord = ''
      }
      this.familyList = []
      this.waitingSelectIndex = -1
    },
    /**
     * 搜索内容输入
     **/
    handleSearchInput() {
      const searchList = []
      if (this.searchWord) {
        // if (this.searchWord[ 0 ] === '=') {
        //   const searchWord = this.searchWord.substr(1)
        //   this.orderList.forEach(item => {
        //     if (item.indexOf(searchWord) !== -1) searchList.push({ label: item, value: item, type: 1 })
        //   })
        //   this.familyList.forEach(item => {
        //     if (item.indexOf(searchWord) !== -1) searchList.push({ label: item, value: item, type: 2 })
        //   })
        // } else {
        this.$store.state.speciesBank.animals.forEach(bird => {
            if (bird.animalName.indexOf(this.searchWord) !== -1 || (bird.pinyinFirst && bird.pinyinFirst.indexOf(this.searchWord.toUpperCase()) !== -1)) {
              searchList.push({ label: bird.animalName, value: bird.keyAnimalID, type: 3 })
            }
          })
        // }
      }
      this.searchList = searchList
    },

    /**
     * 结果选择
     * @param item
     */
    handleResultSelect(item) {
      this.searchWord = item.label
      this.selectItem = item
      this.$emit('select', item)
    },

    handleKeyboardDown(even) {
      if (!this.focus || !this.searchList.length) return
      if (even.code === 'ArrowDown' || even.keyCode === 40) {
        if (this.waitingSelectIndex >= this.searchList.length - 1) this.waitingSelectIndex = 0
        else this.waitingSelectIndex++
        document.querySelectorAll('.ir-option')[this.waitingSelectIndex].scrollIntoView(false)
      } else if (even.code === 'ArrowUp' || even.keyCode === 38) {
        if (this.waitingSelectIndex <= 0) this.waitingSelectIndex = this.searchList.length - 1
        else this.waitingSelectIndex--
        document.querySelectorAll('.ir-option')[this.waitingSelectIndex].scrollIntoView(false)
      } else if (even.code === 'Enter' || even.keyCode === 13) {
        if (this.waitingSelectIndex !== -1) {
          this.handleResultSelect(this.searchList[this.waitingSelectIndex])
          this.$refs.searchInput.blur()
        }
      }
      console.log(even)
    }
  },
  mounted() {
    // const orderList = []
    // const familyList = []
    // this.$store.state.speciesBank.animals.forEach(bird => {
    //   if (orderList.indexOf(bird.orderCn) === -1) orderList.push(bird.orderCn)
    //   if (familyList.indexOf(bird.familyCn) === -1) familyList.push(bird.familyCn)
    // })
    // this.orderList = orderList
    // this.familyList = familyList
    document.addEventListener('keydown', this.handleKeyboardDown, false)
  },
}
</script>

<style lang="scss" scoped>
  .animal-search-input {
    position: relative;
    .hi-input {
      position: relative;
      .ii-clear {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        color: #f1f1f1;
      }
    }

    .hi-result {
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateY(100%);
      z-index: 999;
      width: 100%;
      white-space: nowrap;
      max-height: 145px;
      overflow: hidden auto;
      text-overflow: ellipsis;
      ul {
        list-style: none;
        background: #052B30;
        li {
          padding: 0 10px;
          height:36px;
          line-height:36px;
          border-top: 1px solid #042023;
          &:hover {
            background: #666;
          }
          &:not(.sel-li) {
            cursor: pointer;
          }
        }
        .hover-li {
          background: #666;
        }
        .sel-li {
          color: rgb(35, 213, 156);
        }
      }
    }
  }
</style>
