<template>
  <div class="map-box" :style="mapStyle">
    <div id="cesiumContainer"></div>
  </div>
</template>

<script>
import * as Cesium from "cesium/Source/Cesium";
import CesiumNavigation from "@/expand/lib/cesium-navigation-es6";
import Heatmap from "heatmap.js";
import "cesium/Source/Widgets/widgets.css";
// import TdtImageryProvider from '@dvgis/cesium-map/src/imagery/tdt/TdtImageryProvider'

/**
 * 自定义GIS数据类型
 * name 名称
 * type 矢量类型（polygon: 多边形；imagePoint: 图片点；multiPolygon: 多个多边形；）
 * coordinates 坐标信息集合 同一级多个坐标第一为外边框，其余为孔
 * properties 属性
 * -- stroke 边框颜色
 * -- stroke-width 边框宽度
 * -- fill 填充颜色
 * -- event 事件处理
 * ---- click 点击事件处理
 * ------ stroke 边框颜色
 * ------ stroke-style 边框样式
 */

export default {
  name: "CesiumMap",
  props: {
    // 多边形默认透明度
    polygonDefaultAlpha: {
      type: Number,
      default: 1,
    },
    selectedEntity: Function,
    highlightEntityClear: Function,
    promptBoxes: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      layers: {},
      heatmapOptions: {
        // name: '',
        // data: [],
        // min: 0,
        // max: 0,
      },
      selectedImagePoints: [], //选中的图片点
    };
  },
  computed: {
    // 地图样式
    mapStyle() {
      return {
        width: `${this.$store.state.system.displayArea.width}px`,
        height: `${this.$store.state.system.displayArea.height}px`,
        transform: `scale(${
          this.$config.calcRatioWidth /
          this.$store.state.system.displayArea.width
        })`,
        transformOrigin: "top left",
      };
    },
  },
  methods: {
    /**
     * 坐标转Cesium坐标
     **/
    coordinatesTurnCartesian3(coordinates) {
      const points = []; // 外边界点
      coordinates.forEach((p) => {
        points.push(p[0], p[1], p[2] || 0);
      });
      return Cesium.Cartesian3.fromDegreesArrayHeights(points);
    },

    /**
     * 多边形实体
     **/
    polygonEntity(coordinates, properties, dataSource, parent) {
      const options = {
        polygon: {
          hierarchy: {
            positions: this.coordinatesTurnCartesian3(coordinates[0]),
            holes: [],
          },
          clampToGround: true,
        },
      };
      // 判断是否包含孔
      if (coordinates.length > 1) {
        for (let i = 1; i < coordinates.length; i++) {
          options.polygon.hierarchy.holes.push(
            this.coordinatesTurnCartesian3(coordinates[i])
          );
        }
      }
      // 属性颜色
      options.expandData = { ...properties, eventType: "Polygon" };
      if (properties.fill) {
        options.polygon.material = Cesium.Color.fromCssColorString(
          properties.fill
        ).withAlpha(this.polygonDefaultAlpha);
      } else {
        options.polygon.material = Cesium.Color.TRANSPARENT;
      }
      if (properties["stroke-width"]) {
        // options.polygon.outline = true;
        // options.polygon.outlineWidth = properties['stroke-width'];
        // if (properties['stroke']) options.polygon.outlineColor = Cesium.Color.fromCssColorString(properties['stroke']).withAlpha(this.polygonDefaultAlpha);
        // 外边界线
        options.polyline = {
          positions: options.polygon.hierarchy.positions,
          material: Cesium.Color.fromCssColorString(
            properties["stroke"]
          ).withAlpha(this.polygonDefaultAlpha),
          width: properties["stroke-width"],
          clampToGround: true,
        };
      }
      // 父级实体
      if (parent) {
        options.parent = parent;
      } else if (properties.id) {
        options.id = properties.id;
      }
      // options.polygon.material = new Cesium.ImageMaterialProperty({
      //   image: item.material.image,
      // });
      dataSource.entities.add(options);
    },

    /**
     * 折线实体
     **/
    polylineEntity(coordinates, properties, dataSource) {
      const options = {
        polyline: {
          positions: this.coordinatesTurnCartesian3(coordinates),
          width: properties["stroke-width"],
          clampToGround: true,
        },
      };
      if (properties.id) options.id = properties.id;
      options.expandData = { ...properties, eventType: "Polyline" };
      const color = properties.stroke
        ? Cesium.Color.fromCssColorString(properties.stroke)
        : Cesium.WHITE;
      // 线条风格 dashed（虚线） solid（实现，默认）
      if (properties["stroke-style"] === "dashed") {
        options.polyline.material = new Cesium.PolylineDashMaterialProperty({
          color,
          dashLength: properties["dash-length"] || 20,
        });
      } else if (properties.stroke) {
        options.polyline.material = color;
      }
      dataSource.entities.add(options);
    },

    /**
     * 多个多边形实体
     **/
    multiPolygonEntity({ coordinates, properties }, dataSource) {
      coordinates.forEach((coords) => {
        this.polygonEntity(
          coords,
          properties,
          dataSource,
          dataSource.entities.add(new Cesium.Entity())
        );
      });
    },

    /**
     * 添加KML数据层
     **/
    async addKmlDataSource(name, uri, option) {
      const highlightDataSource = window.viewer.dataSources.getByName(
        "highlight-dataSource"
      )[0];
      highlightDataSource.entities.removeAll();
      const dataSource = await Cesium.KmlDataSource.load(uri, {
        camera: window.viewer.scene.camera,
        canvas: window.viewer.scene.canvas,
        clampToGround: true,
      });
      dataSource.name = name;
      if (option) {
        if (option.show === false) {
          dataSource.show = false;
        }
        if (option.entity) {
          if (option.entity.polygon) {
            dataSource.entities.values.forEach((entity) => {
              if (entity.polygon && option.entity.polygon) {
                if (option.entity.polygon.fill)
                  entity.polygon.material = Cesium.Color.fromCssColorString(
                    option.entity.polygon.fill
                  ).withAlpha(this.polygonDefaultAlpha);
                if (option.entity.polygon.stroke) {
                  entity.polyline = {
                    positions: entity.polygon.hierarchy._value.positions,
                    material: Cesium.Color.fromCssColorString(
                      option.entity.polygon.stroke
                    ).withAlpha(this.polygonDefaultAlpha),
                    width: option.entity.polygon.strokeWidth,
                    clampToGround: true,
                  };
                }
              }
            });
          }
        }
        if (option.is_fly) {
          window.viewer.flyTo(dataSource, {
            duration: 2,
            offset: new Cesium.HeadingPitchRange(
              Cesium.Math.toRadians(0),
              Cesium.Math.toRadians(-90)
            ),
          });
        }
      }
      window.viewer.dataSources.add(dataSource);
      window.viewer.dataSources.raiseToTop(highlightDataSource);
    },

    /**
     * 添加数据集合
     * @param name 名称
     * @param data 数据
     * @param option 配置参数
     * @param option.is_fly 是否直接显示至集合
     */
    addDataSource(name, data, option) {
      const highlightDataSource = window.viewer.dataSources.getByName(
        "highlight-dataSource"
      )[0];
      highlightDataSource.entities.removeAll();
      const dataSource = new Cesium.CustomDataSource(name);
      data.forEach((item) => {
        if (item.properties) {
          item.properties.belongDataSourceName = name;
        } else {
          item.properties = {
            belongDataSourceName: name,
          };
        }
        if (item.type === "Polygon") {
          this.polygonEntity(item.coordinates, item.properties, dataSource);
        } else if (item.type === "MultiPolygon") {
          this.multiPolygonEntity(item, dataSource);
        } else if (item.type === "LineString") {
          this.polylineEntity(item.coordinates, item.properties, dataSource);
        } else if (item.type === "ImagePoint") {
          dataSource.entities.add({
            id: item.properties.id,
            position: Cesium.Cartesian3.fromDegrees(...item.coordinates),
            billboard: item.properties.label
              ? undefined
              : {
                  image: item.image,
                  heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
                  // verticalOrigin: Cesium.HorizontalOrigin.BOTTOM,
                },
            label: item.properties.label
              ? {
                  text: item.properties.label,
                  font: "28px 微软雅黑",
                  scale: 0.5,
                  pixelOffset: new Cesium.Cartesian2(0, 40),
                  heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
                }
              : undefined,
            // label: (item.properties.label ? {text: item.properties.label, font: '16px', verticalOrigin: Cesium.VerticalOrigin.BOTTOM, pixelOffset: new Cesium.Cartesian2(0, 40), heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND} : undefined),
            expandData: {
              ...item.properties,
              eventType: "ImagePoint",
              coordinates: item.coordinates,
            },
          });
        }
      });
      window.viewer.dataSources.add(dataSource);
      window.viewer.dataSources.raiseToTop(highlightDataSource);
      if (option.is_fly) {
        window.viewer.flyTo(dataSource, {
          duration: 2,
          offset: new Cesium.HeadingPitchRange(
            Cesium.Math.toRadians(0),
            Cesium.Math.toRadians(-90)
          ),
        });
      }
    },

    /**
     * 移除数据源
     * @param name
     */
    removeDataSource(name) {
      window.viewer.dataSources.remove(
        window.viewer.dataSources.getByName(name)[0],
        true
      );
      this.clearHighlightEntity(name);
    },

    /**
     * 设置数据源显示
     **/
    setDataSourceVisible(name, show) {
      const dataSource = window.viewer.dataSources.getByName(name)[0];
      if (dataSource) dataSource.show = show;
    },

    /**
     * 打开地形图
     * @param url
     */
    openTerrainData(url) {
      window.viewer.terrainProvider = new Cesium.CesiumTerrainProvider({
        url,
        requestVertexNormals: true,
        requestWaterMask: true,
      });
    },

    /**
     * 关闭地形图
     */
    closeTerrainData() {
      window.viewer.terrainProvider = new Cesium.EllipsoidTerrainProvider();
    },

    /**
     * 添加瓦片图层
     * @param name
     * @param data
     * @param option
     * @param option.show 是否显示
     */
    addUrlTemplateImageryProvider(name, data, option = {}) {
      const imageLayer = window.viewer.imageryLayers.addImageryProvider(
        new Cesium.UrlTemplateImageryProvider({
          url: data.url,
          subdomains: data.subdomains,
          maximumLevel: 18,
          show: option.show || false,
        })
      );
      if (!option.show) {
        imageLayer.show = false;
      }
      this.layers[name] = window.viewer.imageryLayers.indexOf(imageLayer);
    },

    // /**
    //  * 添加天地图瓦片图层
    //  * @param name
    //  * @param data
    //  * @param option
    //  * @param option.show 是否显示
    //  */
    // addTDTTemplateImageryProvider(name, data, option = {}) {
    //   const imageLayer = viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
    //     url: [
    //       '',
    //       '//t{s}.tianditu.gov.cn/DataServer?T={style}_w&x={x}&y={y}&l={z}&tk={key}'.replace(/\{style\}/g, data.style || 'vec').replace(
    //         /\{key\}/g,
    //         'abefad9b06cbf3fe992ad3d68d0e202c'
    //       )
    //     ].join(''),
    //     subdomains: ['0', '1', '2', '3', '4', '5', '6', '7'],
    //     maximumLevel: 18
    //   }))
    //   if (!option.show) {
    //     imageLayer.show = false
    //   }
    //   this.layers[name] = window.viewer.imageryLayers.indexOf(imageLayer)
    // },

    /**
     * 显示瓦片图层
     * @param name
     */
    showImageLayer(...name) {
      name.forEach((n) => {
        window.viewer.imageryLayers.get(this.layers[n]).show = true;
      });
    },

    /**
     * 不显示瓦片图层
     * @param name
     */
    hideImageLayer(...name) {
      name.forEach((n) => {
        window.viewer.imageryLayers.get(this.layers[n]).show = false;
      });
    },

    /**
     * 设置透明度
     * @param name
     * @param alpha
     */
    setVectorAlpha(name, alpha) {
      window.viewer.dataSources
        .getByName(name)[0]
        .entities.values.forEach((entity) => {
          if (name === "heatmap-dataSource") {
            // 热力图
            window.heatmapInstance.configure({ opacity: alpha });
            entity.rectangle.material = new Cesium.ImageMaterialProperty({
              image: entity.rectangle.material.image._value,
              transparent: entity.rectangle.material.transparent._value,
            });
          } else {
            if (entity.polygon) {
              entity.polygon.material = new Cesium.Color(
                entity.polygon.material._color._value.red,
                entity.polygon.material._color._value.green,
                entity.polygon.material._color._value.blue,
                alpha
              ); //._color._value.alpha  = alpha;
            } else if (entity.polyline) {
              entity.polyline.material = new Cesium.Color(
                entity.polyline.material._color._value.red,
                entity.polyline.material._color._value.green,
                entity.polyline.material._color._value.blue,
                alpha
              );
            }
          }
        });
    },

    /**
     * 添加热力图
     * @param name 名称
     * @param data 数据
     * @param option 配置参数
     * @param option.min 最小值
     * @param option.max 最大值
     * @param option.is_fly 是否居中
     **/
    addHeatmap2(name, data, option) {
      if (option.min === undefined) {
        option.min = Math.min(...data.map((n) => n.value));
      }
      if (option.max === undefined) {
        option.max = Math.max(...data.map((n) => n.value));
      }
      this.heatmapOptions = {
        name,
        data,
        min: option.min,
        max: option.max,
      };
      if (!window.heatmapInstance) {
        // 创建DIV
        const container = document.createElement("div");
        container.id = "heatmap-container";
        container.style.width = window.viewer.scene.canvas.width + "px";
        container.style.height = window.viewer.scene.canvas.height + "px";
        container.style.display = "none";
        document.body.appendChild(container);
        // 创建热力图
        window.heatmapInstance = Heatmap.create({
          container: container,
          opacity: this.polygonDefaultAlpha,
          gradient: {
            0: "#1A58AD",
            ".25": "#24F77A",
            ".5": "#FFFF5C",
            ".75": "#EE4138",
          },
          radius: 10,
        });
      } else {
        window.heatmapInstance.configure({ opacity: this.polygonDefaultAlpha });
      }
      // 热力图数据集
      let heatmapDataSource =
        window.viewer.dataSources.getByName("heatmap-dataSource");
      if (heatmapDataSource && heatmapDataSource.length) {
        heatmapDataSource = heatmapDataSource[0];
        window.viewer.dataSources.raiseToTop(heatmapDataSource);
      } else {
        heatmapDataSource = new Cesium.CustomDataSource("heatmap-dataSource");
        window.viewer.dataSources.add(heatmapDataSource);
      }
      this.heatmapDraw();
      window.viewer.camera.moveEnd.addEventListener(this.heatmapDraw);
      if (option.is_fly) {
        if (name === "3-7-1") {
          window.viewer.flyTo(heatmapDataSource, {
            duration: 2,
            offset: new Cesium.HeadingPitchRange(
              Cesium.Math.toRadians(0),
              Cesium.Math.toRadians(-90)
            ),
          });
        } else {
        }
      }
    },

    /**
     * 添加热力图
     * @param name 名称
     * @param data 数据
     * @param option 配置参数
     * @param option.min 最小值
     * @param option.max 最大值
     * @param option.is_fly 是否居中
     **/
    addHeatmap(name, data, option) {
      let lonMin = data[0].x; // 最小经度
      let lonMax = data[0].x; // 最大经度
      let latMin = data[0].y; // 最小纬度
      let latMax = data[0].y; // 最大纬度
      let minValue = 0; // 最小值
      let maxValue = 0; // 最大值
      // 计算最小最大经纬度
      data.forEach((item) => {
        if (item.x < lonMin) lonMin = item.x;
        if (item.x > lonMax) lonMax = item.x;
        if (item.y < latMin) latMin = item.y;
        if (item.y > latMax) latMax = item.y;
        if (item.value < minValue) minValue = item.value;
        if (item.value > maxValue) maxValue = item.value;
      });
      // 范围外500米左右
      lonMin -= 0.006;
      lonMax += 0.006;
      latMin -= 0.006;
      latMax += 0.006;
      const originWidth = lonMax - lonMin;
      const originHeight = latMax - latMin;
      const width = 1920;
      const height = 1920 / (originWidth / originHeight);
      this.heatmapOptions = {
        name,
        data,
        min: option.min,
        max: option.max,
      };
      if (!window.heatmapInstance) {
        // 创建DIV
        const container = document.createElement("div");
        container.id = "heatmap-container";
        container.style.width = width + "px";
        container.style.height = height + "px";
        container.style.display = "none";
        document.body.appendChild(container);
        // 创建热力图
        window.heatmapInstance = Heatmap.create({
          container: container,
          opacity: this.polygonDefaultAlpha,
          radius: 100,
          gradient: {
            0: "#1A58AD",
            ".25": "#24F77A",
            ".5": "#FFFF5C",
            ".75": "#EE4138",
          },
        });
      } else {
        window.heatmapInstance.configure({ opacity: this.polygonDefaultAlpha });
      }
      // data = [{"x":114.47187423706055,"y":24.504019317712647,"value":1},{"x":114.47582244873047,"y":24.494334531633775,"value":1},{"x":114.46758270263672,"y":24.501363886086274,"value":1},{"x":114.46277618408203,"y":24.49792736189651,"value":1},{"x":114.46929931640624,"y":24.492147541216028,"value":1},{"x":114.47187423706055,"y":24.484024101014608,"value":1},{"x":114.46037292480469,"y":24.48433655072229,"value":1},{"x":114.44406509399414,"y":24.437772992870002,"value":1},{"x":114.46535110473631,"y":24.463088203403675,"value":1},{"x":114.45779800415038,"y":24.470431787829494,"value":1},{"x":114.4717025756836,"y":24.443399034681697,"value":1},{"x":114.48543548583983,"y":24.450743766923264,"value":1},{"x":114.43737030029297,"y":24.486992341908426,"value":1},{"x":114.45985794067383,"y":24.465900690594616,"value":1},{"x":114.46552276611328,"y":24.47277539482679,"value":1},{"x":114.46638107299805,"y":24.482461840837402,"value":1},{"x":114.46088790893555,"y":24.478399873599642,"value":1},{"x":114.4639778137207,"y":24.489960512768402,"value":1},{"x":114.47032928466797,"y":24.49933322405472,"value":1},{"x":114.47221755981445,"y":24.479181031328192,"value":1},{"x":114.48251724243164,"y":24.444024134939934,"value":1}].map(d => {
      //   return {x: Math.floor((d.x - lonMin) / w2 * width), y: Math.floor(height - ((d.y - latMin) / h2 * height)), value: 1};
      // });
      // 由于纬度是从大到小需要用总高度减一下
      window.heatmapInstance.setData({
        min: option.min !== undefined ? option.min : minValue,
        max: 1000, //option.max !== undefined ? option.max : maxValue,
        data: data.map((item) => ({
          x: Math.floor(((item.x - lonMin) / originWidth) * width),
          y: Math.floor(height - ((item.y - latMin) / originHeight) * height),
          value: item.value,
        })),
      });
      // 热力图数据集
      let heatmapDataSource =
        window.viewer.dataSources.getByName("heatmap-dataSource");
      if (heatmapDataSource && heatmapDataSource.length) {
        heatmapDataSource = heatmapDataSource[0];
        window.viewer.dataSources.raiseToTop(heatmapDataSource);
      } else {
        heatmapDataSource = new Cesium.CustomDataSource("heatmap-dataSource");
        window.viewer.dataSources.add(heatmapDataSource);
      }
      // 热力图画板
      const canvas = document.getElementsByClassName("heatmap-canvas");
      heatmapDataSource.entities.add({
        id: name,
        rectangle: {
          coordinates: Cesium.Rectangle.fromDegrees(
            lonMin,
            latMin,
            lonMax,
            latMax
          ),
          material: new Cesium.ImageMaterialProperty({
            image: canvas[0],
            transparent: true,
          }),
        },
      });
      window.viewer.camera.moveEnd.addEventListener(this.heatmapDraw);
      if (option.is_fly) {
        if (name === "3-7-1") {
          window.viewer.flyTo(heatmapDataSource, {
            duration: 2,
            offset: new Cesium.HeadingPitchRange(
              Cesium.Math.toRadians(0),
              Cesium.Math.toRadians(-90)
            ),
          });
        }
      }
    },

    /**
     * 删除热力图
     **/
    removeHeatmap(id) {
      const heatmapDataSource =
        window.viewer.dataSources.getByName("heatmap-dataSource");
      if (heatmapDataSource && heatmapDataSource.length) {
        window.viewer.camera.moveEnd.removeEventListener(this.heatmapDraw);
        return heatmapDataSource[0].entities.removeById(id);
      }
      return false;
    },

    /**
     * 重绘热力图
     **/
    heatmapDraw() {
      // var cameraPosition = viewer.scene.camera.positionWC;
      // var ellipsoidPosition = viewer.scene.globe.ellipsoid.scaleToGeodeticSurface(cameraPosition);
      // var distance = Cesium.Cartesian3.magnitude(Cesium.Cartesian3.subtract(cameraPosition, ellipsoidPosition, new Cesium.Cartesian3()));
      // function heightToZoom(height){
      //   const A = 40487.57;
      //   const B = 0.00007096758;
      //   const C = 91610.74;
      //   const D = -40467.74;
      //   return Math.round(D+(A-D)/(1+Math.pow(height/C, B)));
      // }
      // const zoom = heightToZoom(Math.ceil(viewer.camera.positionCartographic.height));
      //       let scene = window.viewer.scene;
      // // 获取画布的大小
      //       var width = scene.canvas.clientWidth;
      //       var height = scene.canvas.clientHeight;
      // //获取画布中心两个像素的坐标（默认地图渲染在画布中心位置）
      //       var left = scene.camera.getPickRay(new Cesium.Cartesian2((width / 2) | 0, (height - 1) / 2));
      //       var right = scene.camera.getPickRay(new Cesium.Cartesian2(1 + (width / 2) | 0, (height - 1) / 2));
      //
      //       var globe = scene.globe;
      //       var leftPosition = globe.pick(left, scene);
      //       var rightPosition = globe.pick(right, scene);
      //
      //       if (!Cesium.defined(leftPosition) || !Cesium.defined(rightPosition)) {
      //         return;
      //       }
      //
      //       var leftCartographic = globe.ellipsoid.cartesianToCartographic(leftPosition);
      //       var rightCartographic = globe.ellipsoid.cartesianToCartographic(rightPosition);
      //       var geodesic = new Cesium.EllipsoidGeodesic();
      //       geodesic.setEndPoints(leftCartographic, rightCartographic);
      //       var distance = geodesic.surfaceDistance;//分辨率
      //       console.log('distance', distance);
      //       // return;
      //       console.log('zoom', zoom);
      //       let radius = (12 - (zoom < 12 ? 12 : zoom) + 12)  * 10;
      //       console.log('radius', radius);
      let radius = Math.ceil(
        window.viewer.camera.positionCartographic.height / 120
      );
      // console.log(window.viewer.camera.positionCartographic.height, radius);
      if (radius > 140) radius = 140;
      else if (radius < 1) radius = 1;
      //       console.log(radius);
      // if (window.heatmapInstance._config.radius === radius) return;
      window.heatmapInstance.configure({ radius });
      window.heatmapInstance._store._radi.forEach((element, x) => {
        element.forEach((coord, y) => {
          window.heatmapInstance._store._radi[x][y] = radius;
        });
      });
      // 重新赋值
      // window.heatmapInstance.setData({
      //   min: this.heatmapOptions.min,
      //   max: this.heatmapOptions.max,
      //   data: this.heatmapOptions.data.map(item => {
      //     const xy = Cesium.SceneTransforms.wgs84ToDrawingBufferCoordinates(window.viewer.scene, Cesium.Cartesian3.fromDegrees(item.x, item.y, 0));
      //     return {
      //       x: Math.round(xy.x),
      //       y: Math.round(xy.y),
      //       value: item.value,
      //     };
      //   }),
      // });
      window.heatmapInstance.repaint();
      // 热力图画板
      const canvas = document.getElementsByClassName("heatmap-canvas");
      // 矩形左上角，右下角
      // const coords = [
      //   window.viewer.scene.globe.pick(window.viewer.camera.getPickRay(new Cesium.Cartesian2(0, 0)), window.viewer.scene),
      //   window.viewer.scene.globe.pick(window.viewer.camera.getPickRay(new Cesium.Cartesian2(window.viewer.scene.canvas.width, window.viewer.scene.canvas.height)), window.viewer.scene),
      // ];
      // console.log(coords);
      // 热力图数据集
      const heatmapDataSource =
        window.viewer.dataSources.getByName("heatmap-dataSource")[0];
      const entity = heatmapDataSource.entities.getById(
        this.heatmapOptions.name
      );
      if (entity) {
        entity.rectangle.material = new Cesium.ImageMaterialProperty({
          image: entity.rectangle.material.image._value,
          transparent: entity.rectangle.material.transparent._value,
        });
        // entity.rectangle = {
        //   coordinates: Cesium.Rectangle.fromCartesianArray(coords),
        //   material: new Cesium.ImageMaterialProperty({
        //     image: canvas[ 0 ],
        //     transparent: true,
        //   }),
        // };
      } else {
        heatmapDataSource.entities.add({
          id: this.heatmapOptions.name,
          rectangle: {
            coordinates: Cesium.Rectangle.fromCartesianArray(coords),
            material: new Cesium.ImageMaterialProperty({
              image: canvas[0],
              transparent: true,
            }),
          },
        });
      }
    },

    /**
     * 选中实体
     * @param entity
     */
    handleSelectedEntity(entity) {
      // 实体不为空且非块级选择且包含拓展信息
      if (
        !entity ||
        entity.id === "highlight-entity" ||
        !(
          entity.expandData &&
          entity.expandData.event &&
          typeof entity.expandData.event.click === "object"
        )
      )
        return;
      if (this.highlightEntity(entity)) {
        this.selectedEntity(entity.id, entity.expandData);
      }
    },

    /**
     * 根据实体ID高亮实体
     * @param id 实体ID
     * @param option 配置信息
     * @param option.dataSourceName 数据集名字
     * @param option.event 依据事件，默认 click
     * @param option.is_fly 是否居中
     * @returns {boolean}
     */
    highlightEntityById(id, option = {}) {
      let entities = window.viewer.entities;
      if (option.dataSourceName) {
        const dataSources = window.viewer.dataSources.getByName(
          option.dataSourceName
        );
        if (dataSources && dataSources.length) {
          entities = dataSources[0].entities;
        }
      }
      const entity = entities.getById(id);
      if (entity) {
        this.highlightEntity(entity, option);
        return true;
      }
      return false;
    },

    /**
     * 高亮实体
     * @param entity 实体对象
     * @param option 配置参数
     * @param option.event 指定根据某个事件类型来变更，默认 click
     * @param option.is_fly 是否居中
     * @returns {boolean}
     */
    highlightEntity(entity, option = {}) {
      const eventInfo =
        typeof option.event === "object"
          ? option.event
          : entity.expandData.event[option.event || "click"] || {};
      if (entity.expandData.eventType === "ImagePoint") {
        const index = this.selectedImagePoints.findIndex(
          (p) => p.id === entity.id
        );
        if (index === -1) {
          this.selectedImagePoints.forEach((p) => {
            let e;
            if (p.dataSourceName) {
              const dataSource = window.viewer.dataSources.getByName(
                p.dataSourceName
              );
              if (dataSource && dataSource.length)
                e = dataSource[0].entities.getById(p.id);
            } else {
              e = window.viewer.entities.getById(p.id);
            }
            if (e) e.billboard.image = e.expandData.defaultImage;
          });
          entity.expandData.defaultImage = entity.billboard.image._value;
          entity.billboard.image = eventInfo.image;
          this.selectedImagePoints = [
            {
              id: entity.id,
              dataSourceName: entity.expandData.belongDataSourceName,
            },
          ];
        }
        if (option.is_fly) {
          window.viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(
              entity.expandData.coordinates[0],
              entity.expandData.coordinates[1],
              window.viewer.camera.positionCartographic.height
            ),
            duration: 0.5,
          });
        }
        return true;
      }
      const highlightDataSource = window.viewer.dataSources.getByName(
        "highlight-dataSource"
      )[0];
      if (highlightDataSource.entities.contains(entity)) return false;
      highlightDataSource.entities.removeAll();
      const highEntity = {
        id: "highlight-entity",
        expandData: {
          originId: entity.id,
          belongDataSourceName: entity.expandData.belongDataSourceName,
        },
      };
      if (entity.expandData.eventType === "Polygon") {
        highEntity.polyline = {
          positions: entity.polygon.hierarchy._value.positions,
          material: Cesium.Color.WHITE,
          width: 2,
          clampToGround: true,
        };
      } else if (entity.expandData.eventType === "Polyline") {
        if (!eventInfo.stroke && !eventInfo["stroke-style"]) return false;
        highEntity.polyline = {
          positions: entity.polyline.positions._value,
          width:
            entity.expandData.event.click["stroke-width"] ||
            entity.expandData["stroke-width"],
          clampToGround: true,
        };
        const strokeStyle =
          eventInfo["stroke-style"] || entity.expandData["stroke-style"];
        const stroke = Cesium.Color.fromCssColorString(
          eventInfo.stroke || entity.expandData.stroke
        );
        if (strokeStyle === "dashed") {
          highEntity.polyline.material =
            new Cesium.PolylineDashMaterialProperty({
              color: stroke,
              dashLength: entity.expandData["dash-length"] || 20,
            });
        } else {
          highEntity.polyline.material = stroke;
        }
      } else {
        return false;
      }
      highlightDataSource.entities.add(highEntity);
      window.viewer.dataSources.raiseToTop(highlightDataSource);
      if (option.is_fly) {
        window.viewer.flyTo(highEntity, {
          duration: 2,
          offset: new Cesium.HeadingPitchRange(
            Cesium.Math.toRadians(0),
            Cesium.Math.toRadians(-90)
          ),
        });
      }
      return true;
    },

    /**
     * 清除高亮实体
     * @param dataSourceName 实体名称
     * @returns {boolean}
     */
    clearHighlightEntity(dataSourceName) {
      const highlightDataSource = window.viewer.dataSources.getByName(
        "highlight-dataSource"
      )[0];
      if (!highlightDataSource.entities.values.length) return false;
      const entity = highlightDataSource.entities.values.find(
        (e) => e.expandData.belongDataSourceName === dataSourceName
      );
      if (entity) {
        highlightDataSource.entities.remove(entity);
        this.highlightEntityClear(entity.expandData.originId);
        return true;
      }
      return false;
    },

    /**
     * 场景渲染监听
     */
    postRender() {
      if (this.promptBoxes.length) {
        this.promptBoxes.forEach((box) => {
          const position = Cesium.SceneTransforms.wgs84ToWindowCoordinates(
            window.viewer.scene,
            Cesium.Cartesian3.fromDegrees(
              ...box.coordinates,
              window.viewer.scene.globe.ellipsoid
            )
          );
          this.$emit("change:prompt-boxes", box.tid, position);
        });
      }
    },
  },
  mounted() {
    Cesium.Ion.defaultAccessToken =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxM2QwY2Q3NC0xZjQ1LTQyMmItODg2Ny1mZGIwYzZhZjU4NDYiLCJpZCI6MjczMDMsImlhdCI6MTYwNjg4MDMwMn0.7CUyjRukQF6iGToVfH7IWGRCq3kJp6eYI3zNP5tWCdM";
    const viewer = new Cesium.Viewer("cesiumContainer", {
      homeButton: false, // 首页位置
      sceneModePicker: false, // 切换2D、3D 和 Columbus View (CV) 模式
      geocoder: false, // 地理位置
      animation: false, // 是否显示动画控件
      baseLayerPicker: false, // 是否显示影像图层选择控件
      timeline: false, // 是否显示时间线控件
      navigationHelpButton: false, // 是否显示帮助信息控件
      fullscreenButton: false, // 是否显示全屏按钮vue2-animate
      selectionIndicator: false, // 关闭选择高亮
      shouldAnimate: true,
      infoBox: false, // 是否显示信息框博客园
      orderIndependentTranslucency: false,
      contextOptions: {
        webgl: {
          alpha: true,
        },
      },
    });
    viewer._cesiumWidget._creditContainer.style.display = "none"; // 屏蔽loge广告
    viewer.imageryLayers.removeAll(true); // 注销默认Cesium影像图层
    // viewer.scene.screenSpaceCameraController.minimumZoomDistance = 2000;// 相机的高度的最小值
    // viewer.scene.screenSpaceCameraController.maximumZoomDistance = 100000; // 相机高度的最大值
    viewer.scene.screenSpaceCameraController.enableLook = false; // 只能通过平移更改摄像机的观看方向或旋转
    viewer.scene.screenSpaceCameraController.translateEventTypes = [
      Cesium.CameraEventType.LEFT_DRAG,
      Cesium.CameraEventType.MIDDLE_DRAG,
    ]; // 设置缩放改为 鼠标中间键和鼠标左键
    viewer.scene.screenSpaceCameraController.zoomEventTypes = [
      Cesium.CameraEventType.WHEEL,
      Cesium.CameraEventType.PINCH,
    ]; // 设置缩放改为 两指和鼠标滚轮
    viewer.scene.screenSpaceCameraController.tiltEventTypes = [
      Cesium.CameraEventType.PINCH,
      Cesium.CameraEventType.RIGHT_DRAG,
    ]; // 设置倾斜改为 两指和鼠标右键
    // viewer.camera.camera.constrainedAxis = Cesium.Cartesian3.UNIT_Z;
    // viewer.scene.screenSpaceCameraController.enableTilt = false; // 相机高度的最大值
    // viewer.scene.screenSpaceCameraController._minimumZoomRate = 30000; // 设置相机缩小时的速率
    // viewer.scene.screenSpaceCameraController._maximumZoomRate = 5906376272000; // 设置相机放大时的速率
    viewer.scene.skyBox.show = false; // 关闭星星夜空
    viewer.scene.skyAtmosphere.show = false; // 关闭天空气氛
    viewer.scene.backgroundColor = Cesium.Color.TRANSPARENT; // 背景色
    viewer.scene.globe.baseColor = Cesium.Color.TRANSPARENT; // 默认图层颜色
    // viewer.scene.globe.depthTestAgainstTerrain = true  // 元素超出隐藏
    // viewer.scene.screenSpaceCameraController.enableLook = false;  // 禁止平移
    viewer.scene.globe.showSkirts = true; // 关闭裙边
    viewer.scene.globe.backFaceCulling = false; // 是否开启背面地形
    viewer.scene.moon.show = false; // 隐藏月亮
    viewer.scene.sun.show = false; // 隐藏太阳
    if (Cesium.FeatureDetection.supportsImageRenderingPixelated()) {
      //判断是否支持图像渲染像素化处理
      viewer.resolutionScale = window.devicePixelRatio;
    }

    //是否开启抗锯齿
    viewer.scene.fxaa = true;
    viewer.scene.postProcessStages.fxaa.enabled = true;
    // 判断是否存在事件
    // 高亮实体添加
    viewer.dataSources.add(new Cesium.CustomDataSource("highlight-dataSource"));
    if (this.selectedEntity) {
      const handle = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      handle.setInputAction((move) => {
        const pick = viewer.scene.pick(move.position);
        if (Cesium.defined(pick) && pick.id) {
          this.handleSelectedEntity(pick.id);
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      // viewer.selectedEntityChanged.addEventListener(this.handleSelectedEntity)
    }
    // 删除鼠标默认左键双击事件
    viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
    );
    // 只显示一块区域
    // 114.4216912188991 114.497548780456 24.426772243628 24.51496128447269
    // viewer.scene.globe.cartographicLimitRectangle = Cesium.Rectangle.fromDegrees(114.4216912188991, 24.426772243628, 114.497548780456, 24.51496128447269);
    CesiumNavigation(viewer, {
      enableCompass: true, // 用于启用或禁用罗盘
      enableZoomControls: false, // 用于启用或禁用缩放控件
      enableDistanceLegend: true, // 用于启用或禁用距离图例
      enableCompassOuterRing: true, // 用于启用或禁用指南针外环
    });
    window.viewer = viewer;
    window.viewer.scene.postRender.addEventListener(this.postRender);
    // const geojson = {"type":"FeatureCollection","features":[{"type":"Feature","geometry":{"type":"Point","coordinates":[113.29657986760138,23.164384999686067]},"properties":{"name":"1号点位","description":"设备23-合璧"}},{"type":"Feature","geometry":{"type":"Point","coordinates":[113.3000660687685,23.168109178212006]},"properties":{"name":"2号点位","description":"设备24-佛境"}},{"type":"Feature","geometry":{"type":"Point","coordinates":[113.30011099576949,23.176091061248453]},"properties":{"name":"3号点位","description":"设备19-碑林"}},{"type":"Feature","geometry":{"type":"Point","coordinates":[113.29787001013754,23.1737633694194]},"properties":{"name":"4号点位","description":"设备20-黄河石台阶"}},{"type":"Feature","geometry":{"type":"Point","coordinates":[113.30170691013336,23.18073892645009]},"properties":{"name":"5号点位","description":"设备25-祈福亭"}},{"type":"Feature","geometry":{"type":"Point","coordinates":[113.30103501677512,23.17843843828034]},"properties":{"name":"6号点位","description":"设备21-水塔"}},{"type":"Feature","geometry":{"type":"Point","coordinates":[113.30788336694239,23.19652258641037]},"properties":{"name":"7号点位","description":"设备27-碧影湾"}},{"type":"Feature","geometry":{"type":"Point","coordinates":[113.30173641443251,23.196844934093036]},"properties":{"name":"8号点位","description":"设备18-竹韵亭"}},{"type":"Feature","geometry":{"type":"Point","coordinates":[113.31326991319656,23.192259264890705]},"properties":{"name":"9号点位","description":"设备14-白云松涛"}},{"type":"Feature","geometry":{"type":"Point","coordinates":[113.28033372759819,23.179118972662057]},"properties":{"name":"10号点位","description":"设备22-果香园"}}]}
    // var terrainProvider = new Cesium.CesiumTerrainProvider({
    //   url: 'http://192.168.20.20:7878/guangdong',
    //   requestVertexNormals: true,
    //   requestWaterMask: true,
    // });
    // var positions = geojson.features.map(item => Cesium.Cartographic.fromDegrees(item.geometry.coordinates[0], item.geometry.coordinates[1]));
    // var promise = Cesium.sampleTerrainMostDetailed (terrainProvider, positions);
    // const _this = this
    // Cesium.when(promise, function(updatedPositions) {
    //   // positions[0].height and positions[1].height have been updated.
    //   // updatedPositions is just a reference to positions.
    //   console.log(updatedPositions)
    //   const points = geojson.features.map((d, i) => {
    //     d.geometry.coordinates[2] = updatedPositions[i].height + 50
    //     return {
    //       type: 'ImagePoint',
    //       coordinates: d.geometry.coordinates,
    //       properties: { id: `dd-${i}`, mold: 'audioDevice', event: { click: { image: '/icon/鸟声设备选中.png' } } },
    //       image: '/icon/鸟声设备.png',
    //     }
    //   })
    //   console.log(JSON.stringify(geojson))
    //   _this.addDataSource('1-3', points, { is_fly: true })
    //
    // });
  },
  beforeUnmount() {
    if (window.viewer) {
      if (window.viewer.selectedEntityChanged.numberOfListeners > 0) {
        window.viewer.selectedEntityChanged.removeEventListener(
          this.handleSelectedEntity
        );
      }
      window.viewer.destroy();
      // window.viewer.scene.postRender.removeEventListener(this.postRender);
      window.viewer = undefined;
    }
  },
};
</script>

<style lang="scss" scoped>
.map-box {
  width: 100%;
  height: 100%;
}

#cesiumContainer {
  width: 100%;
  height: 100%;
}
</style>
