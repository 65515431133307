<template>
  <div class="collect-data">
    <div class="right-circle">
      <div class="tc-circle tc-1"></div>
      <div class="tc-circle tc-2"></div>
      <div class="tc-circle tc-3"></div>
      <div class="tc-circle tc-4"></div>
      <div class="tc-content">
        <div class="cc-device" v-if="info.frameType === 'device'">
          <div class="cd-name">{{ info.name }}</div>
          <div class="cd-items">
            <div class="di-item">
              <div class="ii-label">收集数据</div>
              <div class="ii-high">{{ voiceTotal }}条</div>
            </div>
            <div class="di-item">
              <div class="ii-label">部署时间</div>
              <div class="ii-high">{{ deployTime }}</div>
            </div>
            <div class="di-item">
              <div class="ii-label">部署生境</div>
              <div class="ii-high">{{ info.remark }}</div>
            </div>
            <div class="di-item">
              <div class="ii-label">部署经度</div>
              <div class="ii-high">{{ info.coordinates[0] }}</div>
            </div>
            <div class="di-item">
              <div class="ii-label">部署纬度</div>
              <div class="ii-high">{{ info.coordinates[1] }}</div>
            </div>
            <!--            <div class="di-item">-->
            <!--              <div class="ii-label">环境噪音</div>-->
            <!--              <div class="ii-high">50db</div>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="cc-animal" v-else>
          <div class="ca-name">{{ info.name }}</div>
          <div class="ca-items">
            <div class="ai-item">
              <div class="ii-label">学名</div>
              <div class="ii-xm">{{
              info.frameType === 'animal' ? info.scientificName : animalData.scientificName
              }}
              </div>
            </div>
            <div class="ai-item">
              <div class="ii-label">目名</div>
              <div class="ii-high">{{ info.frameType === 'animal' ? info.orderCN : animalData.orderCN }}</div>
            </div>
            <div class="ai-item">
              <div class="ii-label">科名</div>
              <div class="ii-high">{{ info.frameType === 'animal' ? info.familyCN : animalData.familyCN }}</div>
            </div>
            <div class="ai-item">
              <div class="ii-label">属名</div>
              <div class="ii-high">{{ info.frameType === 'animal' ? info.genusCN : animalData.genusCN }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div class="tb-close" @click="handleClose"></div>
      <div class="tb-data" v-show="!appraisalShow">
        <div class="tb-charts">
          <AnimalOfMonth ref="animalOfMonth" v-show="info.frameType === 'animal'" />
          <CollectCharts ref="collectCharts" v-show="info.frameType !== 'animal'" />
        </div>
        <div class="tb-table">
          <div class="bt-head">
            <table border="0" cellpadding="0" cellspacing="0">
              <colgroup>
                <col width="20%">
                <col width="20%">
                <col width="20%">
                <col width="20%">
                <col width="20%">
              </colgroup>
              <thead>
                <th>声谱图</th>
                <th>采集时间</th>
                <th>{{ info.frameType === 'device' ? '鉴定物种' : '采集设备' }}</th>
                <th>鉴定方式</th>
                <th>识别率</th>
                <!--              <th>操作</th>-->
              </thead>
            </table>
          </div>
          <div class="bt-body" @scroll="handleVoiceScroll">
            <table>
              <colgroup>
                <col width="20%">
                <col width="20%">
                <col width="20%">
                <col width="20%">
                <col width="20%">
                <!--                <col width="10%">-->
              </colgroup>
              <tbody>
                <tr v-for="(row, index) in voiceList" :key="row.keyRecogID"
                  :class="index % 2 !== 0 ? '' : 'bt-striped'">
                  <td style="width: 20%">
                    <div class="bt-cell">
                      <div class="audio-img" v-audio-player="row.recogSoundUrl">
                        <img :src="row.recogSpectUrl || ''" alt="" />
                      </div>
                    </div>
                  </td>
                  <td style="width: 20%">
                    <div class="bt-cell">{{ row.collectionTime }}</div>
                  </td>
                  <td style="width: 20%">
                    <div class="bt-cell">{{
                    info.frameType === 'device' ? (row.animals && row.animals.length ? row.animals[ 0 ].name : '——') :
                    row.deviceName
                    }}
                    </div>
                  </td>
                  <td style="width: 20%">
                    <div class="bt-cell">{{ row.recogType }}</div>
                  </td>
                  <td style="width: 10%">
                    {{ row.animals && row.animals.length ? ((row.animals[ 0 ].score * 100).toFixed(2) + '%') : '——' }}
                  </td>
                  <!--                <td style="width: 10%">-->
                  <!--                  <div class="bt-cell">-->
                  <!--                    <button class="action-btn" @click="handleAppraisalOpen(row)">复核</button>-->
                  <!--                  </div>-->
                  <!--                </td>-->
                </tr>
              </tbody>
            </table>
            <div class="not-data" v-if="voiceLoading">正在加载</div>
            <div class="not-data" v-else-if="!voiceList.length">暂无数据</div>
          </div>
        </div>
      </div>
      <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
        <template v-if="appraisalShow">
          <collect-appraisal @back="handleAppraisalClose" />
        </template>
      </transition>
    </div>
  </div>
</template>

<script>
import AnimalOfMonth from '_c/mapData/collectData/AnimalOfMonth'
import CollectCharts from '_c/mapData/collectData/CollectCharts'
import moment from 'moment'
import CollectAppraisal from '_c/mapData/collectData/CollectAppraisal'
import { getVoiceList } from '_r/apis'

export default {
  name: 'CollectDataIndex',
  components: { CollectAppraisal, CollectCharts, AnimalOfMonth },
  props: {
    info: {
      type: Object,
      default: {},
    },
  },
  computed: {
    deployTime() {
      if (this.info.deployTime) {
        return moment(this.info.deployTime).format('YYYY-MM-DD')
      }
      return ''
    },
  },
  watch: {
    info() {
      if (this.info.frameType) {
        console.log('info', this.info)
        this.handleInfoLoad()
      }
    },
  },
  data() {
    return {
      appraisalShow: false,
      appraisalInfo: {},
      voiceTotal: 0,
      voiceList: [],
      voiceLoading: false,
      pageIndex: 1,
      animalData: {},
    }
  },
  methods: {
    /**
     * 鉴定关闭
     */
    handleAppraisalClose() {
      this.appraisalShow = false
    },
    /**
     * 鉴定打开
     */
    handleAppraisalOpen(info) {
      this.appraisalInfo = info
      this.appraisalShow = true
    },
    /**
     * 关闭
     */
    handleClose() {
      this.appraisalShow = false
      this.$emit('close')
    },
    /**
     * 数据变更
     **/
    async handleInfoLoad() {
      if (this.appraisalShow) this.handleAppraisalClose()
      if (this.info.frameType === 'animal') {
        this.$refs.animalOfMonth.handleLoad(this.info.keyAnimalID)
      } else {
        this.$refs.collectCharts.handleLoad(this.info.keyDeviceID)
        if (this.info.frameType === 'device-animal') {
          let animalData = this.$store.getters.getAnimalById(this.info.keyAnimalID)
          if (!animalData) {
            animalData = await this.$store.dispatch('updateAnimalByID', this.info.keyAnimalID)
          }
          this.animalData = animalData
        }
      }
      await this.handleVoiceData(1)
    },
    /**
     * 声纹数据
     * @returns {Promise<void>}
     */
    async handleVoiceData(pageIndex) {
      if (this.voiceLoading) return;
      this.pageIndex = pageIndex
      if (pageIndex === 1) {
        this.voiceTotal = 0
        this.voiceList = []
      }
      this.voiceLoading = true
      const params = { pageSize: 20, pageIndex: this.pageIndex }
      if (this.info.frameType !== 'animal') params.deviceID = this.info.keyDeviceID
      if (this.info.frameType === 'device-animal') params.animalID = this.info.keyAnimalID
      else if (this.info.frameType === 'animal') params.animalID = this.info.keyAnimalID
      const res = await getVoiceList(params)
      if (res.messageType === 0) {
        res.data.forEach(item => {
          if (this.info.frameType !== 'device') {
            item.deviceName = (this.$store.state.userInfo.unitDevice.find(d => d.keyDeviceID === item.fKDeviceID) || { deviceUserName: '' }).deviceUserName
          }
          this.voiceList.push(item)
        })
      }
      this.voiceLoading = false
      this.voiceTotal = res.totalCount
    },

    /**
     * 声纹数据滚到底
     * @param event
     */
    handleVoiceScroll(event) {
      if (event.target.scrollHeight - event.target.scrollTop <= event.target.offsetHeight && this.voiceList.length < this.voiceTotal) {
        this.handleVoiceData(this.pageIndex + 1)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@keyframes rightCircleTop {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-25deg);
  }
}

@keyframes rightCircleBottom {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(25deg);
  }
}

.collect-data {
  position: absolute;
  top: 174px;
  left: 504px;

  .right-circle {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-46%);
    width: 320px;
    height: 320px;

    .tc-circle {
      position: absolute;
      left: 0;
      top: 0;
      width: 320px;
      height: 320px;
    }

    .tc-1 {
      background: url('~_a/mapData/circle1.png') no-repeat 0;
    }

    .tc-2 {
      z-index: 1;
      background: url('~_a/mapData/circle2.png') no-repeat 0;
      animation: rotate360 10s linear infinite;
    }

    .tc-3 {
      z-index: 2;
      background: url('~_a/mapData/circle3.png') no-repeat 0;
      animation: rightCircleTop 1.5s ease infinite alternate;
    }

    .tc-4 {
      z-index: 2;
      background: url('~_a/mapData/circle4.png') no-repeat 0;
      animation: rightCircleBottom 1.5s ease infinite alternate;
    }

    .tc-content {
      padding: 58px;
      position: relative;
      z-index: 4;

      .ii-high {
        color: #56F6B5;
      }

      .cc-device {
        text-align: center;

        .cd-name {
          font-size: 16px;
          margin-bottom: 6px;
        }

        .cd-items {
          .di-item {
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            line-height: 30px;

            .ii-label {
              margin-right: 10px;
            }
          }
        }
      }

      .cc-animal {
        text-align: center;

        .ca-name {
          color: #FFFFFF;
          font-size: 20px;
          margin: 10px 0;
        }

        .ca-items {
          .ai-item {
            display: flex;
            align-items: center;
            white-space: nowrap;
            line-height: 34px;

            .ii-label {
              width: 66px;
            }

            .ii-xm {
              font-size: 12px;
              font-style: italic;
            }
          }
        }
      }
    }
  }

  .right-box {
    width: 1077px;
    height: 777px;
    margin-left: 283px;
    padding: 34px 0 0 103px;
    background: url('~_a/mapData/collect-detail.png') no-repeat 0;

    .tb-close {
      position: absolute;
      top: 13px;
      right: 3px;
      z-index: 1;
      width: 26px;
      height: 26px;
      cursor: pointer;
      background: url('~_a/login/close.png') no-repeat 0;
    }

    .tb-table {
      width: 948px;
      height: 309px;
      padding-left: 4px;
      position: relative;
      background: url('~_a/mapData/collect-table.png') no-repeat 0;

      table {
        width: 938px;
        border: 0;
        border-collapse: collapse;
      }

      .bt-head {
        height: 34px;
        line-height: 34px;
      }

      .bt-body {
        height: 258px;
        overflow-y: auto;

        table {
          text-align: center;
          position: relative;

          tbody {
            width: 100%;
            overflow-y: auto;
          }

          .bt-cell {
            padding: 10px 0;

            .action-btn {
              width: 60px;
              height: 32px;
              color: #28D9A3;
              line-height: 32px;
              background: #052E37;
              cursor: pointer;
              border: 1px solid #24E38B;
              border-radius: 2px;
              transition: background-color .2s ease;

              &:hover {
                background-color: rgba(#24E38B, .2);
              }
            }
          }

          .bt-striped {
            td {
              background: #063B45;
            }
          }

          .audio-img {
            width: 160px;
            height: 50px;
            line-height: 50px;
            margin: 0 auto;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .not-data {
          text-align: center;
          line-height: 60px;
        }
      }
    }
  }
}
</style>
