<template>
  <div class="discern-list">
    <div class="bl-title">识别信息</div>
    <div class="nl-info">
      <div class="li-name">{{ info.name }}</div>
      <div class="li-info" v-if="info.frameType === 'device'">
        <div><span class="ii-label">型号：</span><span class="nl-high">{{info.deviceModel}}</span></div>
        <!--        <div><span class="ii-label">环境噪音：</span><span class="nl-high">55db</span></div>-->
        <div><span class="ii-label">收集数据：</span><span class="nl-high">{{voiceTotal}}条</span></div>
      </div>
      <div class="li-info2" v-else>
        <div><span class="ii-label">学名：</span><span class="nl-high nl-italic">{{ animalData.scientificName }}</span>
        </div>
        <div><span class="ii-label">目名：</span><span class="nl-high">{{ animalData.orderCN }}</span></div>
        <div><span class="ii-label">科名：</span><span class="nl-high">{{ animalData.familyCN }}</span></div>
      </div>
    </div>
    <div class="nl-list" @scroll="handleVoiceScroll">
      <div class="ll-item" v-for="item in voiceList" :key="item.keyRecogID">
        <div class="li-top">
          <div class="li-audio" v-audio-player="item.recogSoundUrl">
            <img :src="item.recogSpectUrl" alt="" class="ia-img" />
          </div>
          <div class="li-sbl">
            <div>识别率</div>
            <div class="nl-high">{{item.animals.length ? ((item.animals[0].score * 100).toFixed(2) + '%') : '——'}}</div>
          </div>
        </div>
        <div class="li-info">
          <div>物种：<span class="nl-high">{{item.animals.length ? item.animals[0].name : '识别失败'}}</span></div>
          <div>采集时间：<span class="nl-high">{{item.collectionTime}}</span></div>
        </div>
      </div>
    </div>
    <div class="nl-detail" @click="$emit('to-collect', 2)">查看详情</div>
  </div>
</template>

<script>
import { getVoiceList } from '_r/apis'

export default {
  name: 'DiscernList',
  props: {
    info: {
      type: Object,
      default: {},
    },
    toCollect: Function,
  },
  data() {
    return {
      voiceTotal: 0,
      voiceList: [],
      pageIndex: 1,
      animalData: {},
      dataLoading: false,
    }
  },
  watch: {
    info() {
      this.handleInfoLoad()
    }
  },
  methods: {
    /**
     * 数据变更
     **/
    async handleInfoLoad() {
      if (this.info.frameType === 'device-animal') {
        let animalData = this.$store.getters.getAnimalById(this.info.keyAnimalID)
        if (!animalData) {
          animalData = await this.$store.dispatch('updateAnimalByID', this.info.keyAnimalID)
        }
        this.animalData = animalData
      }
      await this.handleVoiceData(1)
    },
    /**
     * 声纹数据
     * @returns {Promise<void>}
     */
    async handleVoiceData(pageIndex) {
      if (this.dataLoading) return;
      this.dataLoading = true;
      this.pageIndex = pageIndex
      if (pageIndex === 1) {
        this.voiceTotal = 0
        this.voiceList = []
      }
      console.log(111, this.info)
      const params = { deviceID: this.info.keyDeviceID, pageSize: 20, pageIndex: this.pageIndex }
      if (this.info.frameType === 'device-animal') params.animalID = this.info.keyAnimalID
      const res = await getVoiceList(params)
      if (res.messageType === 0) {
        res.data.forEach(item => {
          this.voiceList.push(item)
        })
      }
      this.voiceTotal = res.totalCount || 0
      this.dataLoading = false
    },

    /**
     * 声纹数据滚到底
     * @param event
     */
    handleVoiceScroll(event) {
      if (event.target.scrollHeight - event.target.scrollTop <= event.target.offsetHeight && this.voiceList.length < this.voiceTotal) {
        this.handleVoiceData(this.pageIndex + 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.discern-list {
  width: 100%;
  height: 100%;
  padding: 10px 0 0 18px;
  position: relative;
  white-space: nowrap;

  .nl-info {
    margin: 12px 0;
    width: 285px;
    height: 149px;
    padding: 15px 24px;
    background: url('~_a/mapData/recog-info-bg.png') no-repeat 0 0;

    .li-name {
      font-size: 22px;
      font-weight: 400;
      margin-bottom: 6px;
    }

    .li-info {
      line-height: 28px;

      .ii-label {
        display: inline-block;
        width: 80px;
        text-align: right;
      }
    }

    .li-info2 {
      line-height: 28px;

      .ii-label {
        display: inline-block;
        width: 50px;
        text-align: right;
      }

      .nl-italic {
        font-style: italic;
        font-size: 14px;
      }
    }
  }

  .nl-high {
    color: #56f6b5;
  }

  .nl-list {
    height: 575px;
    overflow-y: auto;

    .ll-item {
      width: 283px;
      height: 133px;
      padding: 15px 24px;
      background: url('~_a/mapData/recog-bg.png') no-repeat 0 0;
      margin-bottom: 12px;
      line-height: 24px;

      .li-top {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .li-audio {
          width: 168px;
          height: 52px;
          line-height: 52px;
          text-align: center;
          background: url('~_a/mapData/audio-bg.png') no-repeat 0;

          .ia-img {
            width: 100%;
            height: 100%;
          }
        }

        .li-sbl {
          margin-left: 18px;
          text-align: center;
        }
      }
    }
  }

  .nl-detail {
    width: 144px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
    background: url('~_a/mapData/detail_bg.png') no-repeat 0 0;
    position: absolute;
    left: 50%;
    bottom: 18px;
    transform: translateX(-82px);
  }
}
</style>
