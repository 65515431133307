<template>
  <div class="panorama-modal">
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
      <div class="am-container">
        <div class="mc-mask"></div>
        <div class="mc-box">
          <div class="cb-title">全景图</div>
          <div class="cb-viewer" id="psvViewer"></div>
          <div class="scenes">

          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Viewer } from 'photo-sphere-viewer'
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'

let viewer
export default {
  name: 'PanoramaModal',
  props: {
    deviceID: String
  },
  data() {
    return {
      visible: true
    }
  },
  mounted() {
    viewer = new Viewer({
      container: document.querySelector('#psvViewer'),
      panorama: '',
      loadingTxt: '正在加载',
      defaultLong: 0,
      defaultLat: 0,
      // navbar: false
    })
  },
  unmounted() {
    viewer.destroy()
    viewer = undefined
  }
}
</script>

<style lang="scss" scoped>
.panorama-modal {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  .mc-mask {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 998;
    background-color: rgba(#000, .4);
  }
  .mc-box {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 999;
    transform: translate(-50%, -50%);
    width: 1319px;
    height: 800px;
    background: url("~_a/dynamicPerception/dialog-bg.png") no-repeat 0 0;
    background-size: 100% 100%;
  }
}
</style>
