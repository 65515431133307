<template>
  <div class="map-data-page">
    <div class="animated bounceInLeft left-frame">
      <div
        class="arrow-switch"
        :class="leftFrameVisible ? 'left-arrow' : 'right-arrow'"
        @click="leftFrameVisible = !leftFrameVisible"
        v-show="!showCollect"
      ></div>
      <transition
        enter-active-class="animated bounceInLeft"
        leave-active-class="animated bounceOutLeft"
      >
        <div class="container" v-show="leftFrameVisible">
          <div class="panel">
            <div class="box">
              <div class="content">
                <div class="trees">
                  <template v-for="(tree, ti) in showTreeData" :key="tree.tid">
                    <div
                      class="tree animated fadeInLeftBig"
                      :style="{
                        animationDuration: '.5s',
                        animationDelay: `${(ti + 1) * 0.2}s`,
                      }"
                      :class="openTrees.includes(tree.tid) ? 'tree-sel' : ''"
                      v-show="
                        !showCollect ||
                        (showCollect &&
                          tree.tid !== '1-0' &&
                          tree.tid !== '1-1' &&
                          tree.tid !== '1-2')
                      "
                      v-if="!tree.noShow"
                    >
                      <!-- v-show="!showCollect || (selTab === '0' || tree.tid === '1-3')" v-if="!tree.noShow"> -->
                      <div class="tree-title" @click="handleOpenTree(tree)">
                        <span class="name">{{ tree.name }}</span>
                        <template v-if="tree.checkbox">
                          <span
                            class="checkbox"
                            @click.stop="handleSelectTree(tree)"
                            v-show="!showCollect"
                          >
                            <div
                              class="check"
                              :class="
                                selectTrees.includes(tree.tid) ? 'checked' : ''
                              "
                            ></div>
                          </span>
                        </template>
                        <span
                          class="arrow"
                          v-else-if="tree.children && tree.children.length"
                        >
                          <i class="iconfont"></i>
                        </span>
                      </div>
                      <template v-if="openTrees.includes(tree.tid)">
                        <div
                          class="tree-content tree-tow-level"
                          v-if="tree.children && tree.children.length"
                        >
                          <template
                            v-for="(node, i1) in tree.children"
                            :key="node.tid"
                          >
                            <div
                              class="tree-node"
                              :class="[
                                openTrees.includes(node.tid) ? 'tn-sel' : '',
                                node.children ? 'has-child' : '',
                              ]"
                            >
                              <div
                                class="tn-title"
                                :class="[
                                  highlightNode === node.tid ? 'highlight' : '',
                                ]"
                                @click="handleOpenTree(node)"
                              >
                                <div
                                  class="checkbox"
                                  v-if="node.radio"
                                  @click.stop="handleSelectTree(node)"
                                >
                                  <div
                                    class="radio"
                                    :class="
                                      selectTrees.includes(node.tid)
                                        ? 'radio-sel'
                                        : ''
                                    "
                                  ></div>
                                </div>
                                <div
                                  class="checkbox"
                                  v-if="node.checkbox"
                                  @click.stop="handleSelectTree(node)"
                                >
                                  <div
                                    class="check"
                                    :class="
                                      selectTrees.includes(node.tid)
                                        ? 'checked'
                                        : ''
                                    "
                                  ></div>
                                </div>
                                <div
                                  class="name animated fadeInLeftBig"
                                  :style="{
                                    animationDuration: '.5s',
                                    animationDelay: `${(i1 + 1) * 0.1}s`,
                                  }"
                                >
                                  {{ node.name }}
                                </div>
                              </div>
                              <template
                                v-if="
                                  node.children && openTrees.includes(node.tid)
                                "
                              >
                                <div
                                  class="tree-content"
                                  v-if="node.children.length"
                                >
                                  <template
                                    v-for="(node2, i2) in node.children"
                                    :key="node2.tid"
                                  >
                                    <div
                                      class="tree-node"
                                      :class="
                                        openTrees.includes(node2.tid)
                                          ? 'tn-sel'
                                          : ''
                                      "
                                    >
                                      <div
                                        class="tn-title"
                                        :style="node2.style ? node2.style : ''"
                                        :class="[
                                          highlightNode === node2.tid
                                            ? 'highlight'
                                            : '',
                                        ]"
                                        @click="handleOpenTree(node2)"
                                      >
                                        <div
                                          class="checkbox"
                                          v-if="node2.checkbox"
                                          @click.stop="handleSelectTree(node2)"
                                        >
                                          <div
                                            class="check"
                                            :class="
                                              selectTrees.includes(node2.tid)
                                                ? 'checked'
                                                : ''
                                            "
                                          ></div>
                                        </div>
                                        <div
                                          class="name animated fadeInLeftBig"
                                          :style="{
                                            animationDuration: '.5s',
                                            animationDelay: `${
                                              (i2 + 1) * 0.1
                                            }s`,
                                          }"
                                        >
                                          {{ node2.name }}
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                                <div class="no-data" v-else>暂无数据</div>
                              </template>
                            </div>
                          </template>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <!--            <div class="tabs" v-show="!showCollect">-->
            <!--              <div class="tab" :class="selTab === tab.tid ? 'tab-sel' : ''" v-for="tab in layers" :key="tab.tid"-->
            <!--                   @click="handleSelectTab(tab.tid)">{{ tab.name }}-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </transition>
    </div>
    <div
      class="animated fadeIn right-frame"
      v-show="rightInfo.tid && !showCollect"
    >
      <div
        class="arrow-switch"
        :class="rightFrameVisible ? 'left-arrow' : 'right-arrow'"
        @click="rightFrameVisible = !rightFrameVisible"
      ></div>
      <transition
        enter-active-class="animated bounceInRight"
        leave-active-class="animated bounceOutRight"
      >
        <div class="container" v-show="rightFrameVisible">
          <div class="panel">
            <div class="box">
              <template v-if="rightInfo.data.frameType === 'animal'">
                <animal-info
                  :info="rightInfo.data"
                  @to-collect="handleOpenCollect"
                />
              </template>
              <template v-else>
                <DiscernList
                  :info="rightInfo.data"
                  @to-collect="handleOpenCollect"
                />
              </template>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <transition
      enter-active-class="animated fadeInRightBig"
      leave-active-class="animated fadeOutRightBig"
    >
      <CollectData
        v-show="showCollect"
        :info="rightInfo.data"
        @close="showCollect = false"
      />
    </transition>
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <CesiumMap
        v-show="!showCollect"
        ref="cesiumMap"
        :polygon-default-alpha="polygonDefaultAlpha"
        :selectedEntity="handleEntitySelect"
        :prompt-boxes="promptBoxes"
        @change:prompt-boxes="handlePromptBoxesChange"
      />
    </transition>
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div class="prompt-boxes" v-show="!showCollect">
        <template v-for="box in promptBoxes">
          <device-prompt-box
            :ref="'promptBox_' + box.tid"
            :info="box.data"
            @close="handleClosePromptBox"
            @to-collect="handleOpenCollect"
          />
        </template>
      </div>
    </transition>
    <!--    <panorama-modal d />-->
  </div>
</template>

<script>
import CesiumMap from "_c/map/CesiumMap";
import DiscernList from "_c/mapData/DiscernList";
import CollectData from "_c/mapData/collectData/Index";
import { getChildElement } from "@/expand/util";
import AnimalInfo from "_c/mapData/AnimalInfo";
import DevicePromptBox from "_c/mapData/DevicePromptBox";
import { getDeviceAnimal } from "_r/apis";
import PanoramaModal from "_c/popup/panoramaModal";

export default {
  name: "MapData",
  components: {
    PanoramaModal,
    AnimalInfo,
    DiscernList,
    CesiumMap,
    CollectData,
    DevicePromptBox,
  },
  data() {
    return {
      polygonDefaultAlpha: 0.6,
      leftFrameVisible: true,
      rightFrameVisible: false,
      layers: [
        { tid: "0", name: "动物", children: [] },
        { tid: "1", name: "图层", children: [] },
      ],
      legends: [], // 图例说明
      selTab: "1",
      openTrees: [], // 打开的树节点
      selectTrees: [], // 选中的树节点
      highlightNode: null, // 高亮选中节点
      rightInfo: {
        // 右侧数据
        tid: "",
        data: {},
      },
      showCollect: false,
      promptBoxes: [], // 弹出悬浮框
    };
  },
  computed: {
    /**
     * 显示的树形数据
     **/
    showTreeData() {
      return this.layers[this.selTab].children || [];
    },
  },
  methods: {
    /**
     * 选中Tab
     * @param tab
     */
    handleSelectTab(tab) {
      if (tab === this.selTab) return;
      this.selTab = tab;
      this.highlightNode = null;
      this.rightFrameVisible = false;
      setTimeout(() => {
        this.rightInfo = { tid: "", data: {} };
      }, 500);
    },

    /**
     * 打开树形菜单
     * @param node
     */
    handleOpenTree(node) {
      this.highlightNode = null;
      if (node.children) {
        const index = this.openTrees.indexOf(node.tid);
        const p_tid = node.tid.substring(0, node.tid.lastIndexOf("-") + 1);
        this.openTrees = this.openTrees.filter((id) => id.indexOf(p_tid) !== 0);
        if (index === -1) {
          this.openTrees.push(node.tid);
        }
        if (node.events && node.events.open) node.events.open(index === -1);
      }
      if (node.events && node.events.click) node.events.click();
    },

    /**
     * 选择树形菜单
     * @param node
     */
    handleSelectTree(node, open = true) {
      const index = this.selectTrees.indexOf(node.tid);
      if (index === -1) {
        this.selectTrees.push(node.tid);
      } else {
        this.selectTrees.splice(index, 1);
      }
      if (open) {
        this.handleOpenTree(node);
      }
      if (node.events && node.events.select) node.events.select(index === -1);
    },

    /**
     * 实体选中
     **/
    handleEntitySelect(tid, data) {
      if (data.mold === "audioDevice") this.handleSelectDevice(tid, true);
    },

    /**
     * 选中设备
     * @param tid
     * @param isMapClick 是否地图点击
     */
    async handleSelectDevice(tid, isMapClick) {
      const tids = tid.split("-");
      const data = {
        ...getChildElement(this.layers, tids),
        frameType: "device",
      };
      // delete data.children
      if (this.rightInfo.tid !== tid) {
        this.rightInfo = { tid, data };
      }
      if (isMapClick) {
        if (this.selTab !== "1") this.selTab = "1";
        if (!this.openTrees.includes(`${tids[0]}-${tids[1]}`))
          this.openTrees.push(`${tids[0]}-${tids[1]}`);
        if (!this.openTrees.includes(tid)) {
          this.openTrees = [
            ...this.openTrees.filter((id) => id.indexOf("1-3-") !== 0),
            tid,
          ];
        }
      } else {
        this.$refs.cesiumMap.highlightEntityById(tid, {
          dataSourceName: `${tids[0]}-${tids[1]}`,
          is_fly: true,
        });
      }
      this.promptBoxes = [
        {
          tid,
          data,
          coordinates: data.coordinates,
          // coordinates: [this.rightInfo.data.deployLon, this.rightInfo.data.deployLat, (this.rightInfo.data.deployAlt || 0) + 200],
        },
      ];
      if (!this.showCollect) {
        this.rightFrameVisible = true;
        this.highlightNode = null;
      }
      if (!data.children.length) {
        const res = await getDeviceAnimal({ deviceID: data.keyDeviceID });
        if (res.messageType === 0) {
          this.layers[tids[0]].children[tids[1]].children[tids[2]].children =
            res.animals.map((item, index) => {
              const animal = {
                ...item,
                tid: `${tids}-${index}`,
                name: item.animalName,
                keyDeviceID: data.keyDeviceID,
                style: { cursor: "pointer" },
                events: {},
              };
              animal.events.click = () => {
                this.handleSelectDeviceAnimal(animal);
              };
              return animal;
            });
        }
      }
    },

    async handleSelectDevice2(tid) {
      const tids = tid.split("-");
      const data = {
        ...getChildElement(this.layers, tids),
        frameType: "device",
      };
      // delete data.children
      if (this.rightInfo.tid !== tid) {
        this.rightInfo = { tid, data };
      }
      if (!this.showCollect) {
        this.rightFrameVisible = true;
        this.highlightNode = null;
      }
      // if (!data.children.length) {
      //   const res = await getDeviceAnimal({deviceID: data.keyDeviceID})
      //   this.layers[tids[0]].children[tids[1]].children[tids[2]].children = res.animals.map((item, index) => {
      //     const animal = {...item, tid: `${tids}-${index}`, name: item.animalName, keyDeviceID: data.keyDeviceID, style: { cursor: 'pointer' }, events: {}}
      //     animal.events.click = () => { this.handleSelectDeviceAnimal(animal) }
      //     return animal
      //   })
      // }
    },

    /**
     * 选择设备动物
     **/
    handleSelectDeviceAnimal(node) {
      if (this.rightInfo.tid !== node.tid) {
        this.highlightNode = node.tid;
        this.rightInfo = {
          tid: node.tid,
          data: { ...node, frameType: "device-animal" },
        };
      }
      if (!this.showCollect) {
        this.rightFrameVisible = true;
      }
    },

    /**
     * 选中动物
     * @param tid
     * @param data
     */
    handleSelectAnimal(tid, data) {
      if (this.rightInfo.tid !== tid) {
        // 判断是否在打开层打开， 打开则去掉
        const opIndex = this.openTrees.indexOf(this.rightInfo.tid);
        if (opIndex !== -1) this.openTrees.splice(opIndex);
        this.highlightNode = tid;
        this.rightInfo = {
          tid,
          data: { ...data, frameType: "animal" },
        };
      }
      if (!this.showCollect) {
        this.rightFrameVisible = true;
      }
    },

    /**
     * 打开鉴定列表
     */
    handleOpenCollect() {
      this.showCollect = true;
    },

    /**
     * 悬浮框变更
     */
    handlePromptBoxesChange(tid, box) {
      let key = `promptBox_${tid}`;
      if (this.$refs[key] && this.$refs[key].length && box) {
        this.$refs[key][0].$el.style.left = `${
          box.x / this.$store.state.system.displayArea.proportion - 226
        }px`;
        this.$refs[key][0].$el.style.top = `${
          box.y / this.$store.state.system.displayArea.proportion - 344
        }px`;
      }
    },

    /**
     * 悬浮框关闭
     */
    handleClosePromptBox(tid) {
      this.promptBoxes = this.promptBoxes.filter((box) => box.tid !== tid);
    },
  },
  mounted() {
    const self = this;
    // 编辑图层Tab
    this.layers[1].children = [
      {
        tid: "1-0",
        name: "边界",
        checkbox: true,
        events: {
          select: (flag) => {
            if (flag) {
              this.$refs.cesiumMap.setDataSourceVisible("1-0", true);
            } else {
              this.$refs.cesiumMap.setDataSourceVisible("1-0", false);
            }
          },
        },
      },
      { tid: "1-1", name: "功能区划", checkbox: true, noShow: true },
      {
        tid: "1-2",
        name: "影像道路",
        checkbox: true,
        events: {
          select: (flag) => {
            if (flag) {
              this.$refs.cesiumMap.showImageLayer("1-2-0", "1-2-1");
              // 添加高程数据
              this.$refs.cesiumMap.openTerrainData(
                "http://120.79.193.237:8016/guangdong"
              );
            } else {
              this.$refs.cesiumMap.hideImageLayer("1-2-0", "1-2-1");
              this.$refs.cesiumMap.closeTerrainData();
            }
          },
        },
      },
    ];
    this.$store.state.userInfo.regionalData.forEach((item, i) => {
      let tid = `1-${i + 3}`;
      const points = [],
        devices = [];
      item.devices.forEach((d, index) => {
        // const device = {...self.$store.state.userInfo.unitDevice.find(ddd => ddd.keyDeviceID === d.id), ...d}
        devices.push({
          tid: `${tid}-${index}`,
          children: [],
          events: {
            open: (flag) => {
              if (flag) {
                self.handleSelectDevice(`${tid}-${index}`);
              }
            },
          },
          style: { cursor: "pointer" },
          ...d,
          keyDeviceID: d.id,
        });
        points.push({
          type: "ImagePoint",
          coordinates: d.coordinates,
          properties: {
            id: `${tid}-${index}`,
            mold: "audioDevice",
            event: { click: { image: "/icon/鸟声设备选中.png" } },
          },
          image: "/icon/鸟声设备.png",
        });
      });
      this.layers[1].children.push({
        tid,
        name: `${item.name}监测站点`,
        checkbox: true,
        events: {
          select: (flag) => {
            if (flag) {
              this.$refs.cesiumMap.addDataSource(tid, points, {
                is_fly: false,
              });
            } else {
              this.$refs.cesiumMap.removeDataSource(tid);
            }
          },
        },
        children: devices,
      });

      this.handleSelectTree(this.layers[1].children[i + 3], false);
    });
    // this.$refs.cesiumMap.addDataSource('all-area', this.$store.state.userInfo.regionalData.map(item => {
    //   return {
    //     type: 'ImagePoint',
    //     coordinates: [item.lon, item.lat, 800],
    //     properties: { label: item.name },
    //   }
    // }), { is_fly: true })
    // 添加卫星瓦片
    this.$refs.cesiumMap.addUrlTemplateImageryProvider("1-2-0", {
      url: "https://t{s}.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=abefad9b06cbf3fe992ad3d68d0e202c",
      subdomains: "01234567",
    });
    // 添加道路瓦片
    this.$refs.cesiumMap.addUrlTemplateImageryProvider("1-2-1", {
      url: "https://t{s}.tianditu.gov.cn/DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=abefad9b06cbf3fe992ad3d68d0e202c",
      subdomains: "01234567",
    });
    // 添加卫星瓦片
    // this.$refs.cesiumMap.addTDTTemplateImageryProvider('1-2-0', { style: 'img' })
    // 添加道路瓦片
    // this.$refs.cesiumMap.addTDTTemplateImageryProvider('1-2-1', { style: 'cia' })
    // 边界 'https://oss.caomufan.net/Spatial/Kml/fca6f9f595d9409ab85e5739f5fe05bd.kml'
    this.$refs.cesiumMap.addKmlDataSource(
      "1-0",
      this.$store.state.userInfo.gisData.boundary,
      {
        is_fly: true,
        entity: {
          polygon: { fill: "#1A3E58", stroke: "#23D59C", strokeWidth: 1 },
        },
      }
    );
    this.handleSelectTree(this.layers[1].children[2]);
    this.handleSelectTree(this.layers[1].children[0]);
    // this.handleSelectTree(this.layers[ 1 ].children[ 3 ], false)
    // const animalList = []
    // const bird = (tid, animal) => {
    //   return {
    //     ...animal, tid, style: { cursor: 'pointer' }, events: {
    //       click: () => {
    //         this.handleSelectAnimal(tid, animal)
    //       },
    //     },
    //   }
    // }
    /*
    this.$store.state.speciesBank.animals.forEach(animal => {
      animal.nodeType = 'animal'
      if (!animal.classCN || !animal.orderCN || !animal.familyCN) return
      const orderIndex = animalList.findIndex(a => a.name === animal.orderCN)
      animal.name = animal.animalName
      if (orderIndex === -1) {
        animalList.push({
          tid: `0-${animalList.length}`,
          name: animal.orderCN,
          children: [
            {
              tid: `0-${animalList.length}-0`,
              name: animal.familyCN,
              children: [bird(`0-${animalList.length}-0-0`, animal)],
            },
          ],
        })
        return
      }
      const familyIndex = animalList[ orderIndex ].children.findIndex(a => a.name === animal.familyCN)
      if (familyIndex === -1) {
        animalList[ orderIndex ].children.push({
          tid: `0-${orderIndex}-${animalList[ orderIndex ].children.length}`,
          name: animal.familyCN,
          children: [bird(`0-${orderIndex}-${animalList[ orderIndex ].children.length}-0`, animal)],
        })
        return
      }
      animalList[ orderIndex ].children[ familyIndex ].children.push(bird(`0-${orderIndex}-${familyIndex}-${animalList[ orderIndex ].children[ familyIndex ].children.length}`, animal))
    })
    this.layers[ 0 ].children = animalList
    */
  },
};
</script>

<style lang="scss" scoped>
.map-data-page {
  width: 100%;
  height: 100%;

  .left-frame {
    height: 863px;
    position: absolute;
    left: 20px;
    top: 140px;
    z-index: 96;

    .arrow-switch {
      width: 29px;
      height: 76px;
      line-height: 76px;
      text-align: center;
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 50%;
      z-index: 9;
      transform: translateY(-50%);
      background: url("~_a/mapData/left-border.png") no-repeat 0;

      &:before {
        content: "";
        width: 15px;
        height: 15px;
        display: inline-block;
        background: url("~_a/mapData/left-arrow.png") no-repeat 0;
        transition: all 0.2s 0.2s ease;
      }
    }

    .right-arrow {
      &:before {
        content: "";
        transform: rotate(180deg);
      }
    }

    .container {
      .panel {
        margin-left: 21px;
        transform: perspective(600px) rotateY(8deg);
        display: flex;
        align-items: center;

        .box {
          width: 403px;
          height: 863px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: url("~_a/mapData/left-bg.png") no-repeat;

          .content {
            width: 356px;
            height: 720px;
            overflow: hidden auto;

            .trees {
              .tree {
                width: 100%;
                margin-bottom: 8px;

                .checkbox {
                  display: block;
                  width: 22px;
                  height: 22px;
                  cursor: pointer;

                  .check {
                    width: 100%;
                    height: 100%;
                    transition: all 0.2s ease;
                    background: url("~_a/mapData/n_selected.png") no-repeat 0 0;
                    background-size: 100%;
                  }

                  .checked {
                    background: url("~_a/mapData/selected.png") no-repeat 0 0;
                    background-size: 100%;
                  }

                  .radio {
                    width: 100%;
                    height: 100%;
                    transition: all 0.2s ease;
                    background: url("~_a/mapData/radio.png") no-repeat 0 0;
                  }

                  .radio-sel {
                    background: url("~_a/mapData/radio-sel.png") no-repeat 0 0;
                  }
                }

                .tree-title {
                  width: 338px;
                  height: 58px;
                  padding: 0 20px 0 50px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  background: url("~_a/mapData/list_up.png") no-repeat center;

                  .arrow {
                    .iconfont {
                      font-size: 12px;

                      &:before {
                        content: "\e6eb";
                      }
                    }
                  }
                }

                & > .tree-content {
                  padding-right: 12px;
                }

                .tree-content {
                  margin-top: 20px;
                  margin-left: 30px;
                  position: relative;

                  &:before {
                    content: "";
                    position: absolute;
                    left: -2px;
                    top: -6px;
                    display: block;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #8a9090;
                  }

                  &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 19px;
                    width: 2px;
                    background-color: #8a9090;
                  }

                  .no-data {
                    color: #888;
                    padding: 10px 0;
                    margin-left: 30px;
                  }

                  .tree-node {
                    position: relative;
                    //padding-right: 6px;

                    &:before {
                      content: "";
                      display: block;
                      position: absolute;
                      left: 2px;
                      top: 19px;
                      width: 12px;
                      height: 2px;
                      background-color: #8a9090;
                    }

                    .tn-title {
                      margin-left: 18px;
                      padding-left: 6px;
                      height: 40px;
                      position: relative;
                      display: flex;
                      align-items: center;
                      transition: all 0.2s ease;

                      .checkbox {
                        margin-right: 6px;
                      }

                      &:hover {
                        background-color: rgba(36, 228, 139, 0.2);
                        color: #59ffd2;
                      }
                    }

                    //.tn-title-sel {
                    //  background-color: rgba(36, 228, 139, 0.2);
                    //  color: #59FFD2;
                    //}

                    .highlight {
                      background-color: rgba(36, 228, 139, 0.2);
                      color: #59ffd2;
                    }
                  }

                  .tn-sel {
                    & > .tn-title {
                      background-color: rgba(36, 228, 139, 0.2);
                      color: #59ffd2;

                      &:before {
                        content: "";
                        transform: rotate(90deg);
                      }
                    }
                  }

                  .has-child {
                    & > .tn-title:before {
                      content: "";
                      position: absolute;
                      left: -25px;
                      top: 12px;
                      z-index: 1;
                      width: 16px;
                      height: 16px;
                      background: url("~_a/mapData/dot.png") no-repeat 0;
                      transition: all 0.2s ease;
                    }
                  }
                }
              }

              .tree-sel {
                .tree-title {
                  background: url("~_a/mapData/list_down.png") no-repeat center;

                  .arrow {
                    .iconfont {
                      &:before {
                        content: "\e6ec";
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .tabs {
          margin-left: -20px;

          .tab {
            width: 28px;
            height: 90px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 16px;
            line-height: 18px;
            background: url("~_a/mapData/tab-bg3.png") no-repeat 0 0;
            transition: all 0.2s ease;

            &:hover {
              color: #57efd8;
              background: url("~_a/mapData/tab-bg6.png") no-repeat 0 0;
            }

            &:first-child {
              background: url("~_a/mapData/tab-bg1.png") no-repeat 0 0;

              &:hover {
                background: url("~_a/mapData/tab-bg4.png") no-repeat 0 0;
              }
            }

            &:last-child {
              background: url("~_a/mapData/tab-bg2.png") no-repeat 0 0;

              &:hover {
                background: url("~_a/mapData/tab-bg5.png") no-repeat 0 0;
              }
            }
          }

          .tab-sel {
            color: #57efd8;
            background: url("~_a/mapData/tab-bg6.png") no-repeat 0 0;

            &:first-child {
              background: url("~_a/mapData/tab-bg4.png") no-repeat 0 0;
            }

            &:last-child {
              background: url("~_a/mapData/tab-bg5.png") no-repeat 0 0;
            }
          }
        }
      }
    }
  }

  .right-frame {
    height: 846px;
    position: absolute;
    right: 20px;
    top: 140px;
    z-index: 96;

    .arrow-switch {
      width: 29px;
      height: 76px;
      line-height: 76px;
      text-align: center;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 50%;
      z-index: 9;
      transform: translateY(-50%);
      background: url("~_a/mapData/right-border.png") no-repeat 0;

      &:before {
        content: "";
        width: 15px;
        height: 15px;
        display: inline-block;
        background: url("~_a/mapData/right-arrow.png") no-repeat 0;
        transition: all 0.2s 0.2s ease;
      }
    }

    .right-arrow {
      &:before {
        content: "";
        transform: rotate(180deg);
      }
    }

    .container {
      .panel {
        margin-right: 29px;
        transform: perspective(600px) rotateY(-8deg);
        display: flex;
        align-items: center;

        .box {
          width: 321px;
          height: 846px;
          background: url("~_a/mapData/right-bg.png") no-repeat;
        }
      }
    }
  }

  .frame {
  }
}
</style>
