<template>
  <div class="sx-select" :style="selectStyle">
    <div class="xs-input" @click="optionShow = !optionShow" :title="searchLabel">
      <input type="text" v-model="searchLabel" :readonly="!search" />
    </div>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" duration="200">
      <div class="xs-options" :class="position === 'top' ? 'xs-options-top' : 'xs-options-bottom'" v-show="optionShow">
        <ul>
          <li class="so-option" :class="value === option.value ? 'sel-li' : ''" v-for="option in options"
              :key="option.value" :title="option.label" @click="handleSelectOption(option)">
            {{ option.label }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import bgPng from '_a/mapData/year-selec.png'

export default {
  name: 'sx-select',
  props: {
    search: Boolean,
    backgroundUrl: String,
    width: {
      type: Number,
      default: 106,
    },
    height: {
      type: Number,
      default: 28,
    },
    options: {
      type: Array,
      default: [],
    },
    value: String,
    position: String,
    customStyle: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      optionShow: false,
    }
  },
  computed: {
    selectStyle() {
      const style = {
        width: `${this.width}px`,
        height: `${this.height}px`,
      }
      if (this.backgroundUrl === undefined) {
        style.background = `url('${bgPng}') no-repeat 0`
      } else if (this.backgroundUrl !== '') {
        style.background = `url('${this.backgroundUrl}') no-repeat 0`
      }
      return { ...style, ...this.customStyle }
    },
    searchLabel() {
      return (this.options.find(op => op.value === this.value) || {}).label
    },
  },
  methods: {
    handleSelectOption(option) {
      this.optionShow = false
      this.$emit('update:value', option.value, option)
    },
    handleBodyClick(event) {
      if (event.target !== this.$el && !this.$el.contains(event.target) && this.optionShow) {
        this.optionShow = false
      }
    },
  },
  mounted() {
    document.body.addEventListener('click', this.handleBodyClick, true)
    console.log(this.selectStyle)
  },
  beforeUnmount() {
    document.body.removeEventListener('click', this.handleBodyClick, true)
  },
}
</script>

<style lang="scss" scoped>
.sx-select {
  position: relative;

  .xs-input {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;

    input {
      width: 100%;
      height: 100%;
      padding: 0 16px 0 10px;
      background: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      color: #FFFFFF;
      font-size: 16px;
    }

    &:after {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      background: url('~_a/mapData/select-arrow.png') no-repeat 0;
      background-size: 100%;
      pointer-events: none;
    }
  }

  .xs-options {
    position: absolute;
    left: 0;
    z-index: 999;
    width: 100%;
    white-space: nowrap;
    max-height: 145px;
    overflow: hidden auto;
    text-overflow: ellipsis;

    ul {
      list-style: none;
      background: rgba(#333, .9);

      li {
        padding: 0 10px;
        height: 28px;
        line-height: 28px;
        border-bottom: 1px solid #666666;

        &:hover {
          background: #666;
        }

        &:not(.sel-li) {
          cursor: pointer;
        }
      }

      .sel-li {
        background: #666;
      }
    }
  }

  .xs-options-top {
    top: 0;
    transform: translateY(-100%);
  }

  .xs-options-bottom {
    bottom: 0;
    transform: translateY(100%);
  }
}
</style>
